import React, { useEffect, useState, Fragment, useRef } from 'react';
import {
	Create,
	SimpleForm,
	TextInput,
	ArrayInput,
	SimpleFormIterator,
	Edit,
	List,
	Datagrid,
	TextField,
	FunctionField,
	Show,
	SimpleShowLayout,
	NumberField,
	ReferenceManyField,
	EmailField,
	AutocompleteInput,
	SelectInput,
	ReferenceInput,
	required,
	email as validateEmail,
	Filter,
	DateInput,
	NumberInput,
	CheckboxGroupInput,
	TopToolbar,
	CreateButton,
	ExportButton,
	EditButton,
	DeleteButton,
	ResourceContextProvider,
	ShowButton,
	ArrayField,
	DateField,
	BooleanField,
	ReferenceField,
	regex,
	useNotify,
	Toolbar,
	useRecordContext,
	Button as RaButton,
	maxLength,useRefresh, useRedirect, ListContextProvider, FilterList, SaveContextProvider, Title, SaveButton, RadioButtonGroupInput, useListContext
} from 'react-admin';
import { Button, Card, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, TextField as MuiTextInput, Typography } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import SendIcon from '@material-ui/icons/Send';
import DoneIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/LiveHelp';
import { Link, useParams } from 'react-router-dom';
import { Line, Scatter } from 'react-chartjs-2'
import { chartDatapoints, chartOptions, tooltipConfig } from '../components/ChartConfig'
import dataProvider from '../networking/DataProvider';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { BulkDeleteButton } from 'react-admin';
import CohortResultButton from '../components/CohortResultButton';
import ReassignJobCoach from '../components/ReassignJobCoach';
import { useHistory } from 'react-router-dom';
import {StatisticsShow} from './Statistics';
import {ReassignShow} from './ReassignCoach';
import { makeStyles } from "@material-ui/core";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import validator from 'validator';
import Draggable from 'react-draggable';
import RangeSlider from 'react-bootstrap-range-slider';
import {LateSurveyList} from '../components/LateSurveys';


// import Modal from '@material-ui/core/Modal';

import ListToolbar from '../components/CasesToolbar';


const CaseTitle = ({ record }) => (
	<span>{record.firstname} {record.lastname}</span>
)

const moment_date_format = "YYYY-MM-DD HH:mm:ss";
const uk_date_format = "DD-MM-YYYY";

// const validatePhone = regex(/^(((\+44\s?|0044\s?)?|(\(?0))((2[03489]\)?\s?\d{4}\s?\d{4})|(1[23456789]1\)?\s?\d{3}\s?\d{4})|(1[23456789][234578][0234679]\)?\s?\d{6})|(1[2579][0245][0467]\)?\s?\d{5})|(11[345678]\)?\s?\d{3}\s?\d{4})|(1[35679][234689]\s?[46789][234567]\)?\s?\d{4,5})|([389]\d{2}\s?\d{3}\s?\d{4})|([57][0-9]\s?\d{4}\s?\d{4})|(500\s?\d{6})|(7[456789]\d{2}\s?\d{6})))$/, 'Must be a valid UK phone number');
// const validatePhone =(phone) =>{
// 	validator.isMobilePhone(phone,'en-GB');
// } 

// const validatePhone = (phone,allValues) => {
// 	if (!validator.isMobilePhone(phone.replace(/\s/g, ''),'en-GB')) {
//         return 'Must be a valid UK mobile number';
//     }
//     return undefined;
// };

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

const validatePhone = (phone,allValues) => {
	// // const phoneNumber = phoneUtil.parse(phone,'en-GB');
	// try{
	// 	if (!phoneUtil.isValidNumberForRegion(phoneUtil.parse(phone, 'GB'), 'GB')) {
	// 		return 'Must be a valid UK mobile number';
	// 	}
	// 	return undefined;
	// }catch{
	// 	return 'Must be a valid UK mobile number';
	// }
	return !!phone ? /^\d{10}$/.test(phone) ? undefined : "Must be a valid 10 digits AU phone number" : undefined;
};

const validateNumber = record => record <= 0 && "Must be a positive number";
const validateDateLimit = data => moment(data, moment_date_format).diff(new Date(), 'days') >= 0 ? undefined : "Must be a date after today"



// set new date based on specific date - offset
const setDateDiff = (date, offset) => {
	let now = moment();
	let ndate = moment(date, moment_date_format).hour(now.hour()).minute(now.minute()).second(now.second()).toDate();
	ndate.setDate(ndate.getDate() - offset);
	return moment.utc(moment(ndate)).format(moment_date_format);
}


const CreateToolbar = props => (
	<Toolbar {...props}>

		<SaveButton disabled={props.pristine} />		
		<Button component={ Link } to="/cases" style = {{color:"blue",position:'absolute',right:'15px'}}>Cancel</Button>	
	</Toolbar>
) 

const minValue = (min, message = 'Invalid input') =>
    value => value && value < min ? message : undefined;

const CaseCreate = (props) => {

	const [defaultNum, setDefaultNum] = useState();	
	
	const [sendNow, setSendNow] = useState(false);

	const [emailValidation, setEmailValidation] = useState([required(), validateEmail()]);

	const [useCustomerEmail, setUseCustomerEmail] = useState(false);

	const [useCustomerEmailValidation, setUseCustomerEmailValidation] = useState([]);

	const [emailExists, setEmailExists] = useState([]);


	useEffect(() =>{
		getDefaultNum();
		// console.log(phoneUtil.isValidNumberForRegion(phoneUtil.parse('+447975777666', 'GB'), 'GB'));
		// console.log('abc');
		
		if(sendNow)
		{
			document.getElementById('scheduled_date-helper-text').style.display = 'none';
			document.getElementById('scheduled_date-helper-text').previousElementSibling.classList.remove("MuiFilledInput-underline");
		}
	},[defaultNum,sendNow])


	const getDefaultNum =async() =>{
		const response = await dataProvider.get('settings');

		if(response.data[0])
		{
			if(response.data[0].value == 'null')
			{
				setDefaultNum('');
			}
			else{
				setDefaultNum(response.data[0].value);
			}
		}

	}

	// const notify = useNotify();
    // const refresh = useRefresh();
    const redirect = useRedirect();

	const notify = useNotify();
		
	const submit = data => {
		dataProvider.create('cases', {
			data: {jobseekers: JSON.stringify([{
				...data,
					next_scheduled_meeting: moment.utc(moment(data.next_scheduled_meeting, moment_date_format).hour(23).minute(59).second(59)).format(moment_date_format),
					scheduled_date: sendNow ?
						moment.utc(new Date()).format(moment_date_format) :
						setDateDiff(data.next_scheduled_meeting, data.scheduled_date),
					email_exist: emailExists
			}])}
		})
		.then(res => res.data)
		.then(data => {
			if (data.error)
				notify(data.error.message, "error");
			else {
				notify("Created successfully");
				redirect(`/cases/${data.data.success[0].id}/show`)
			}
		})
	};

	function success(data,basePath)
	{
		redirect(`/cases/${data.data.data[0].id}/show`);
	}

	function sendNowEvent(event){
		document.getElementById("scheduled_date").value = null; 
		setSendNow(event.target.checked);
	}

	function removeRequiredEmail(event){
		//this.form.get('email').setValidators(null);
		console.log("No email required");
		if(event.target.checked){
			//Not using customer email field
			setEmailValidation([]);
			setUseCustomerEmail(true);
			setUseCustomerEmailValidation([required()]);
			setEmailExists(true);
			return true;
		} else {
			//using customer email field
			setEmailValidation([required(), validateEmail()]);
			setUseCustomerEmail(false);
			setUseCustomerEmailValidation([]);
			setEmailExists(false);

			return false;
		}
	}


	// return <Create {...props} onSuccess = {success} onCancel={() => redirect('/cases')} title={<span>Create new job seeker</span>}>
	return <SaveContextProvider value="cases">
		<Create {...props} onCancel={() => redirect('/cases')} title={<span>Create new job seeker</span>}>
			<SimpleForm toolbar={<CreateToolbar/>} save={submit}>
				<h3>Job seeker details</h3>

				<div className = 'form-group'>
					<p style = {{minWidth: '150px',marginRight:'25px'}}>Case ID *</p>
					<TextInput helperText="Internal Case ID" source="job_seeker_id" label="" variant="outlined" validate={[required()]} />
				</div>

				<div className = 'form-group'>
					<p style = {{minWidth: '150px',marginRight:'25px'}}>First name *</p>
					<TextInput source="firstname" label="" variant="outlined" formClassName="inline-form" validate={[required()]}/>
				</div>
				
				<div className = 'form-group'>
					<p style = {{minWidth: '150px',marginRight:'25px'}}>Last name *</p>
					<TextInput source="lastname" label="" variant="outlined" formClassName="inline-form" validate={[required()]} />
				</div>

				<div />

				{!useCustomerEmail && 
				<div className = 'form-group'>
					<p style = {{minWidth: '150px',marginRight:'25px'}}>Email *</p>
					<TextInput  source="email" label="" variant="outlined" formClassName="inline-form" hidden="false" validate={emailValidation} />
				</div> }

				{useCustomerEmail && 
				<div className = 'form-group workcoach-email'>
					<p style = {{minWidth: '150px',marginRight:'25px'}}>Where should this be sent *</p>
					<ReferenceInput label="" source="email" reference="workcoaches"  filter={{ deleted_status: false }} sort={{field:'lastname', order: 'ASC'}}>
						<AutocompleteInput 
							variant="outlined" 
							label="" 
							optionText={record => `${record.firstname} ${record.lastname} - ${record.username}`}
							/>
							
					</ReferenceInput>
				</div>}

				<div className = 'form-group'>
					<p style = {{minWidth: '150px',marginRight:'25px'}}>JobSeeker doesn't have an email</p>
					<Checkbox variant="outlined" id="email_exist" source="email_exist" name="email_exist" inputProps={{ 'aria-label': 'true' }} onChange={(event) => { removeRequiredEmail(event)}} />
				</div>

				<div className = 'form-group'>
					<p style = {{minWidth: '150px',marginRight:'25px'}}>Mobile phone</p>
					<TextInput source="phone" label="" variant="outlined" formClassName="inline-form" validate={[validatePhone]} />
				</div>

				<div className = 'form-group'>
					<p style = {{minWidth: '150px',marginRight:'25px'}}>Linked job coach *</p>
					<ReferenceInput label="" source="case_work_coach_id" reference="workcoaches" sort={{field:'lastname', order: 'ASC'}} filter={{ deleted_status: false }}>
						<AutocompleteInput variant="outlined" label="" optionText={record => `${record.firstname} ${record.lastname} - ${record.username}`}  validate={[required()]}/>
					</ReferenceInput>
				</div>

				<div className = 'form-group'>
					<p style = {{minWidth: '150px',marginRight:'25px'}}>Location</p>
					<ReferenceInput label="" source="case_location_id" reference="locations" sort={{field:'name', order: 'ASC'}}>
						<SelectInput variant="outlined" label="" resettable validate={[required()]}/>
					</ReferenceInput>
				</div>

				<h3>Inital meeting time</h3>
				<div className = 'form-group'>
					<p style = {{minWidth: '150px',marginRight:'25px'}}>Meeting date *</p>
					<DateInput variant="outlined" source="next_scheduled_meeting" label="" validate={[required(), validateDateLimit]} />
				</div>
				<div className = 'form-group'>
					<p style = {{minWidth: '150px',marginRight:'25px'}}>Send Survey</p>
					<div>
					<div style = {{display:'flex'}}>
						<NumberInput key={sendNow} className='numberInput' variant="outlined" source="scheduled_date" label="" min={1} onChange={(event) => { setSendNow(false) }} validate={!sendNow ? [required(), validateNumber] : undefined} initialValue={defaultNum}/>
						<p>days before first meeting</p>
					</div>
						<FormControlLabel control={<Radio name="send_now" inputProps={{ 'aria-label': 'true' }} checked={sendNow === true} />} onChange={(event) => { sendNowEvent(event)}} label="Send now" />
					{/* <RadioButtonGroupInput source="send_now" label="" choices = {[{id: true, name: 'Send now'}]} value={sendNow} onChange={() => setSendNow(true)} /> */}
					</div>
				</div>



			</SimpleForm>
		</Create>
	</SaveContextProvider>
}




const CaseEditToolbar = (props) => (
	<TopToolbar>
		<Link to={`/cases/${props.id}/schedule`} style={{ textDecoration: 'none' }}>
			<Button style={{ padding: '3px 5px', margin: '0 10px' }} color="primary">
				<TodayIcon />Schedule Survey
			</Button>
		</Link>
		<ShowButton basePath='/cases' record={{ id: props.id }} />
	</TopToolbar>
)


const BottomToolbar = props => (
	<Toolbar {...props}>
		<SaveButton disabled={props.pristine} />		
		<Button component={ Link } to="/cases" style = {{color:"blue",position:'absolute',right:'15px'}}>Cancel</Button>	
	</Toolbar>
) 

const matchSuggestion = (filter, choice) => {
    return (
        choice.firstname.toLowerCase().includes(filter.toLowerCase())
        || choice.lastname.toLowerCase().includes(filter.toLowerCase())
    );
};

const filterToQuery = searchText => ({ name_ilike: `%${searchText}%` });

//const CaseCreate = (props) => {
const CaseEdit = (props) => {
	const redirect = useRedirect();
	const refresh = useRefresh();

	function success(data,basePath)
	{
		redirect(`/cases/`+props.id+`/show`);
		refresh();
	}

	return <Edit {...props}  onSuccess = {success} title={<CaseTitle />} redirect="show" mutationMode="pessimistic" actions={<CaseEditToolbar id={props.id} />}>
		<SimpleForm variant="outlined" toolbar={<BottomToolbar/>}>
			<div className='form-group'>
				<p style={{ minWidth: '150px', marginRight: '25px' }}>Case ID *</p>
				<TextInput source="job_seeker_id" label="" validate={[required()]} variant="outlined" />
			</div>

			<div className='form-group'>
				<p style={{ minWidth: '150px', marginRight: '25px' }}>First name *</p>
				<TextInput source="firstname" label="" variant="outlined" formClassName="inline-form" validate={[required()]} />
			</div>

			<div className='form-group'>
				<p style={{ minWidth: '150px', marginRight: '25px' }}>Last name *</p>
				<TextInput source="lastname" label="" variant="outlined" formClassName="inline-form" validate={[required()]} />
			</div>

			<div />

			<div className='form-group'>
				<p style={{ minWidth: '150px', marginRight: '25px' }}>Email *</p>
				<TextInput source="email" label="" variant="outlined" formClassName="inline-form" validate={[required(), validateEmail()]} />
			</div>

			<div className='form-group'>
				<p style={{ minWidth: '150px', marginRight: '25px' }}>Mobile phone</p>
				<TextInput source="phone" label="" variant="outlined" formClassName="inline-form" validate={[validatePhone]} />
			</div>

			<div className='form-group'>
				<p style={{ minWidth: '150px', marginRight: '25px' }}>Outcome</p>
				<SelectInput source="outcome" choices={[
					{ id: 'In work - post placement', name: 'In work - post placement' },
					{ id: 'Suspended', name: 'Suspended' },
					{ id: 'Transferred', name: 'Transferred' }, 
					{ id: 'Exited', name: 'Exited' },
				]}
				/>
			</div>

			<div className='form-group'>
				<p style={{ minWidth: '150px', marginRight: '25px' }}>Other Outcome</p>
				<TextInput source="outcome" label="" variant="outlined" formClassName="inline-form" validate={[]} />
			</div>

			<div className = 'form-group'>
					<p style = {{minWidth: '150px',marginRight:'25px'}}>Location</p>
					<ReferenceInput label="" source="case_location_id" reference="locations" sort={{field:'name', order: 'ASC'}}>
						<SelectInput variant="outlined" label="" resettable validate={[required()]}/>
					</ReferenceInput>
				</div>

			<div className='form-group'>
				<p style={{ minWidth: '150px', marginRight: '25px' }}>Linked job coach *</p>
				
				<ReferenceInput label="" source="case_work_coach_id" reference="workcoaches" filter={{ deleted_status: false }} sort={{field:'lastname', order: 'ASC'}}>
                    <AutocompleteInput variant="outlined" label="" optionText={record => `${record.firstname} ${record.lastname} - ${record.username}`} validate={[required()]} />
                </ReferenceInput>
			</div>
		</SimpleForm>
	</Edit>
}


const CaseFilter = props => {
	const { filterValues } = useListContext();

	return <Filter {...props} >
		<TextInput label="Search" source="search" alwaysOn resettable />
		<TextInput label="Search assigned coach" source="coach" resettable/>
		<DateInput label="Next meeting" source="next_scheduled_meeting" />
		<DateInput label="Last survey completed" source="completed_at" />
		<DateInput label="Case creation date" source="date_created" />
		<TextInput label="Locations" source="case_location_id" resettable/>
	</Filter>
};


const CaseList = (props) => {
	const [selected,setSelected] = useState();
	const [showAside, setShowAside] = useState(false);
	const [timeNow, setTimeNow] = useState(new Date());
	const [savedFilters, setSavedFilters] = useState({});
	const [showAsideReassign, setShowAsideReassign] = useState(false);



	const PostBulkActionButtons = props => (
		<Fragment>
			<CohortResultButton label="Reset Views" {...props} />
			<ReassignJobCoach label="Reset Views" {...props} />
			{/* Add the default bulk delete action */}
			{/* <BulkDeleteButton  {...props} /> */}
		</Fragment>
	);

	const refresh = useRefresh();

	const handler = (ids) => {
		setSelected(ids);
		setShowAside(true);
		setTimeNow(new Date());
		document.body.style.overflow = "auto";
	}

	const handlerReassign = (ids) => {
		setSelected(ids);
		setShowAsideReassign(true);
		setTimeNow(new Date());
		document.body.style.overflow = "auto";
	}

	const onClose = () => {
		setShowAside(false);
	}

	const onCloseReassign = () => {
		setShowAsideReassign(false);
		refresh();

	}
	
	const Aside = () => (
		<div style={{ width:'50vw',height:'50vh', margin: '1em' }}>
			<StatisticsShow selected={selected} />
		</div>

	);

	const useStyles = makeStyles({
		paper: {
			marginTop:0
		}
	  });
	
	const classes = useStyles();

	const Empty = props => (
		<div>
			<Typography class="location-title" component="h2" variant="h5">No notifications</Typography>
		</div>
			
	);


    const NoneActions = props => (
        ''
    );

    const NonePagination = props => (
        ''
    );

	return (
		<div>
		<ResourceContextProvider value="cases">
			
			
			<List filters={<CaseFilter />} filter={{ save_filter: (data) => setSavedFilters(data) }}
				actions={<ListToolbar handler={handler} savedFilters={savedFilters} onUpload={() => refresh()} />}
				bulkActionButtons={<PostBulkActionButtons handler={handler} handlerReassign={handlerReassign}/>}  
				{...props} title="Jobseekers">
				<Datagrid rowClick="show">
					<TextField source="job_seeker_id" />
					<FunctionField label="Name" render={record => `${record.firstname} ${record.lastname}`} />
					<TextField source="phone" />
					<EmailField source="email" />
					<DateField source="next_scheduled_meeting" locales="en-GB" />
					<ReferenceField label="Assigned work coach" source="case_work_coach_id" reference="workcoaches">
						<FunctionField render={record => `${record.firstname} ${record.lastname} - ${record.username}`} />
					</ReferenceField>
					<ReferenceField label="Location" source="case_location_id" reference="locations">
						<FunctionField render={record => `${record.name}`} />
					</ReferenceField>
				</Datagrid>
			</List>
			<Draggable>
			<Dialog fullscreen="true" classes={{paper:classes.paper}} hideBackdrop disableBackdropClick style={{ right: 0,left: 'auto',top: '120px',bottom: 'auto'}} open={showAside} onClose={onClose} maxWidth={"xl"}>
				<DialogActions>
					<Button onClick={onClose} style ={{position:'absolute',left:'16px', top:'25px',color:'grey'}}>
					 	<CloseIcon></CloseIcon>
       				</Button>
				</DialogActions>
				<DialogContent >
					<StatisticsShow selected={selected} refresh = {timeNow}></StatisticsShow>
				</DialogContent>

			</Dialog>
			
			</Draggable>
			<Draggable>
			<Dialog fullscreen="true" classes={{paper:classes.paper}} hideBackdrop disableBackdropClick style={{ right: 0,left: 'auto',top: '120px',bottom: 'auto'}} open={showAsideReassign} onClose={onClose} maxWidth={"xl"}>
				<DialogActions>
					<Button onClick={onCloseReassign} style ={{position:'absolute',left:'16px', top:'25px',color:'grey'}}>
					 	<CloseIcon></CloseIcon>
       				</Button>
				</DialogActions>
				<DialogContent>
					<ReassignShow selected={selected} refresh = {timeNow} close={onCloseReassign}></ReassignShow>
				</DialogContent>

			</Dialog>
			</Draggable>

		</ResourceContextProvider>
		<ResourceContextProvider value="workcoach_case_reminders" name='workcoach_case_reminders' class="workcase-reminders">
			
			
			<LateSurveyList
				{...props} title=" "
				bulkActionButtons={false} empty={<Empty/>}
				actions={<Empty />}
				filter={{ coach_id2: localStorage.getItem('id'), outcome:'' }}
				value="locations"
				class={"workcase-reminders"}
				className={"workcase-reminders"}
				style={{'justify-content':'initial'}}>
			</LateSurveyList>
		</ResourceContextProvider>
		</div>
	)
};




// the stats graph on the side of the show page
const CaseShowAside = ({ record }) => {
	const [data, setData] = useState([]);
	const [result, setResult] = useState('');
	useEffect(() => {
		record && fetchData(record.id);
	}, [record]);

	const fetchData = async (id) => {
		let ds = await dataProvider.getManyReference('survey-instance-history', { id: [id] });
		setData([{
			...chartDatapoints,
			pointBackgroundColor: (context) => {
				let index = 200 - context.dataIndex * (200 / context.dataset.data.length);
				// let index = 150;
				return `rgb(${index},${index},${index})`
			},
			showLine: true,
			lineTension: 0,
			data: ds.data.map(item => ({ x: item.confidence, y: item.motivation, date: item.completed_at, round: item.round, id : item.id }))
		}])
		console.log(data);
		if (typeof data[0] == 'object'){
			var x = data[0].data[0]['x'];
			var y = data[0].data[0]['y'];
			switch(true){
				case x && y && x >= 9.5 && y > 9.5:
					setResult('Independent');
				break;
				case x && y && x > 9.5 && y <= 9.5:
					setResult('Excite');
				break;
				case x && y && x <= 9.5 && y < 9.5:
					setResult('Ignite');
				break;
				case x && y && x < 9.5 && y >= 9.5:
					setResult('Guide');
				break;
				default:
					setResult('');
				break;
			}
		};
		
	}

	const config = {
		tooltips: {
			callbacks: {
				label: (item, data) => {
					data = data.datasets[0].data;
					return [
						`Confidence: ${item.label}`,
						`Motivation: ${item.value}`,
						`Submitted: ${moment(data[item.index].date).format(uk_date_format)}`,
						`Round: ${data[item.index].round}`
					];
				},
			},
			...tooltipConfig
		}
	}

	const plugins = [{
		beforeDraw: (chartInstance, easing) => {
			const ctx = chartInstance.chart.ctx;
			const scaleX = chartInstance.scales['x-axis-0'];
			const scaleY = chartInstance.scales['y-axis-0'];
			ctx.beginPath();
			ctx.moveTo(scaleX.left,scaleY.bottom/2.3+5);
			ctx.lineTo(scaleX.right, scaleY.bottom/2.3+5);
			ctx.strokeStyle = 'grey';
			ctx.stroke();
			ctx.beginPath();
			ctx.moveTo(scaleX.right / 1.6,scaleY.top);
			ctx.lineTo(scaleX.right / 1.6, scaleY.bottom);
			ctx.strokeStyle = 'grey';
			ctx.stroke();
		}
	}];

	return <div className="show-box show-right">
		<h3>Survey result history</h3>
		<div style={{ flex: 1, padding: "0px 40px" }}>
			<Scatter width={500} height={500}
				plugins={plugins}
				onElementsClick={elems => elems.length > 0 ? window.location.href = `/#/survey-instances/${data[0].data[elems[0]._index].id}/show` : null}
				data={{ datasets: data }}
				options={{ ...chartOptions, ...config }} />
			<b>{result}</b>
			<p><i>Darker color represent more recent results</i></p>
			<p><i>Click on the data point to see survey response details</i></p>
		</div>
	</div>
}


const ResendSurvey = async(caseId) => {
	const response = await dataProvider.get(`schedule_now/cases/${caseId}`);
	if(response.data[0] == 'null')
	{
		document.getElementById("resent-survey").style.color = "red";
		const myTimeout = setTimeout(function(){document.getElementById("resent-survey").style.color = "black";}, 5000);	
	}
	else{
		document.getElementById("resent-survey").style.color = "green";
		const myTimeout = setTimeout(function(){document.getElementById("resent-survey").style.color = "black";}, 5000);

	}
}

const ShowToolbar = props => {
	const [showModal, setShowModal] = useState(false);
	const [currSliderVal, setCurrSliderVal] = useState(false);
	const [lastIncompleteSurvey, setLastIncompleteSurvey] = useState(false);
	const [question1Val, setQuestion1Val] = useState(false);
	const [question2Val, setQuestion2Val] = useState(false);
	const [question3Val, setQuestion3Val] = useState(false);
	const [question4Val, setQuestion4Val] = useState(false);
	const [question5Val, setQuestion5Val] = useState(false);
	const [question6Val, setQuestion6Val] = useState(false);
	const [disableButton, setDisableButton] = useState(true);
	const refresh = useRefresh();


	const url = process.env.REACT_APP_API_URL;

	const getLastIncompleteSurveyId = () => {
		var returnValue = false;
		const response = dataProvider.get('survey-instance-history', { _filter:'{"id":['+props.id+']}' }).then(result => {
			console.log(result);
			result.data.forEach((el)=>{
				if(!el.completed_at){
					setLastIncompleteSurvey(el.id);
					returnValue = el.id;
				}
			})

			return returnValue;

		});

		return returnValue;
	}

	useEffect(() => {
		initialiseLastIncompleteSurveyId();
	  }, []); 


	const initialiseLastIncompleteSurveyId = async() => {
		var returnValue = false;
		const response = await dataProvider.get('survey-instance-history', { _filter:'{"id":['+props.id+']}' }).then(result => {
			result.data.forEach((el)=>{
				if(!el.completed_at){
					setLastIncompleteSurvey(el.id);
					console.log("thing has completed running");
					returnValue = el.id;
				}
			})
			
			if(returnValue > 0){
				setDisableButton(false);
			}
		});
	}

	const onHelpSurvey = () => {
		setShowModal(true);
		setCurrSliderVal(3);
		setQuestion1Val(3);
		setQuestion2Val(3);
		setQuestion3Val(3);
		setQuestion4Val(3);
		setQuestion5Val(3);
		setQuestion6Val(3);

		getLastIncompleteSurveyId();
	}

	const submitAnswers = () => {
		const query = new URLSearchParams(window.location.search);


		let body = new URLSearchParams();
		//var history = useHistory();


		console.log("Submitting for id "+lastIncompleteSurvey+" with id "+localStorage.getItem('id'))
		const response = dataProvider.post('survey-instances_id', {
			survey_id:lastIncompleteSurvey,
			token:lastIncompleteSurvey,
			coach_id:localStorage.getItem('id'),
			question_1_answer:question1Val,
			question_2_answer:question2Val,
			question_3_answer:question3Val,
			question_4_answer:question4Val,
			question_5_answer:question5Val,
			question_6_answer:question6Val})
			.then(result => {
				refresh();
				initialiseLastIncompleteSurveyId();
				setShowModal(false);
				console.log(result);
		});

		console.log("submitted");

	}

	
	return <TopToolbar className="toolbar">
		<Link to={`/cases/${props.id}/schedule`} style={{ textDecoration: 'none' }}>
			<Button style={{ padding: '3px 5px', margin: '0 10px' }} color="primary">
				<TodayIcon />Schedule Survey
			</Button>
		</Link>
		<RaButton classname="resend-button" id='resent-survey' label="Resend Survey" onClick={() => ResendSurvey(props.id)} style={{ marginLeft: '10px' , marginRight: '15px'}}>
			<SendIcon className="send"></SendIcon>
		</RaButton>
		<RaButton classname="help-button" id='help-survey' disabled={disableButton} label="Help Survey" onClick={onHelpSurvey}>
			<HelpIcon className="help"></HelpIcon>
		</RaButton>
		<EditButton basePath={props.basePath} record={{ id: props.id }} />

		<Dialog open={showModal} maxWidth='20vw' id="help-survey-dialog">
		<DialogTitle id="form-dialog-title">Survey Assistance for Survey {lastIncompleteSurvey}</DialogTitle>
			<DialogContent>
				<div style={{marginBottom:'10px'}}>Assist this client by filling in the survey for them</div>
				<div>
					<div className='large-main-text'>How confident are you in your ability to look for work?</div>
					<div>
						<span className='range-slider__wrap range-slider__wrap--lg'>
							<input type='range'
								className={`${question1Val < 0 ? 'slider-hide' : 'slider-visible'} slider range-slider range-slider--lg range-slider--primary custom-range-sider`}
								size="lg" tooltip="hide" tooltipPlacement="top"
								value={question1Val}
								onChange={e => {
									setQuestion1Val(e.target.value);
								}}
								onClick={e => {
									// if (hideThumb) {
										//setHideThumb(false);
									// }
								}}
								min={1} max={5} />
							<div className={`range-slider__tooltip range-slider__tooltip--auto range-slider__tooltip--top`} style={{ left: `calc(${(question1Val - 1) * 25}% - ${(question1Val - 3) * 6}px)` }}>
								<div className="range-slider__tooltip__label">{question1Val}</div>
								<div className="range-slider__tooltip__arrow"></div>
							</div>
						</span>
					</div>
				</div>
				<div>
					<div className='large-main-text'>How committed are you to doing job search activities?</div>
					<div>
						<span className='range-slider__wrap range-slider__wrap--lg'>
							<input type='range'
								className={`${question2Val < 0 ? 'slider-hide' : 'slider-visible'} slider range-slider range-slider--lg range-slider--primary custom-range-sider`}
								size="lg" tooltip="hide" tooltipPlacement="top"
								value={question2Val}
								onChange={e => {
									setQuestion2Val(e.target.value);
								}}
								onClick={e => {
									// if (hideThumb) {
										//setHideThumb(false);
									// }
								}}
								min={1} max={5} />
							<div className={`range-slider__tooltip range-slider__tooltip--auto range-slider__tooltip--top`} style={{ left: `calc(${(question2Val - 1) * 25}% - ${(question2Val - 3) * 6}px)` }}>
								<div className="range-slider__tooltip__label">{question2Val}</div>
								<div className="range-slider__tooltip__arrow"></div>
							</div>
						</span>
					</div>
				</div>
				<div>
					<div className='large-main-text'>Think about the people in your life. How supportive will they be as you look for work?</div>
					<div>
						<span className='range-slider__wrap range-slider__wrap--lg'>
							<input type='range'
								className={`${question3Val < 0 ? 'slider-hide' : 'slider-visible'} slider range-slider range-slider--lg range-slider--primary custom-range-sider`}
								size="lg" tooltip="hide" tooltipPlacement="top"
								value={question3Val}
								onChange={e => {
									setQuestion3Val(e.target.value);
								}}
								onClick={e => {
									// if (hideThumb) {
										//setHideThumb(false);
									// }
								}}
								min={1} max={5} />
							<div className={`range-slider__tooltip range-slider__tooltip--auto range-slider__tooltip--top`} style={{ left: `calc(${(question3Val - 1) * 25}% - ${(question3Val - 3) * 6}px)` }}>
								<div className="range-slider__tooltip__label">{question3Val}</div>
								<div className="range-slider__tooltip__arrow"></div>
							</div>
						</span>
					</div>
				</div>
				<div>
					<div className='large-main-text'>Do you believe that your skills will help you find work?</div>
					<div>
						<span className='range-slider__wrap range-slider__wrap--lg'>
							<input type='range'
								className={`${question4Val < 0 ? 'slider-hide' : 'slider-visible'} slider range-slider range-slider--lg range-slider--primary custom-range-sider`}
								size="lg" tooltip="hide" tooltipPlacement="top"
								value={question4Val}
								onChange={e => {
									setQuestion4Val(e.target.value);
								}}
								onClick={e => {
									// if (hideThumb) {
										//setHideThumb(false);
									// }
								}}
								min={1} max={5} />
							<div className={`range-slider__tooltip range-slider__tooltip--auto range-slider__tooltip--top`} style={{ left: `calc(${(question4Val - 1) * 25}% - ${(question4Val - 3) * 6}px)` }}>
								<div className="range-slider__tooltip__label">{question4Val}</div>
								<div className="range-slider__tooltip__arrow"></div>
							</div>
						</span>
					</div>
				</div>
				<div>
					<div className='large-main-text'>Have you started to think about your job options and goals?</div>
					<div>
						<span className='range-slider__wrap range-slider__wrap--lg'>
							<input type='range'
								className={`${question5Val < 0 ? 'slider-hide' : 'slider-visible'} slider range-slider range-slider--lg range-slider--primary custom-range-sider`}
								size="lg" tooltip="hide" tooltipPlacement="top"
								value={question5Val}
								onChange={e => {
									setQuestion5Val(e.target.value);
								}}
								onClick={e => {
									// if (hideThumb) {
										//setHideThumb(false);
									// }
								}}
								min={1} max={5} />
							<div className={`range-slider__tooltip range-slider__tooltip--auto range-slider__tooltip--top`} style={{ left: `calc(${(question5Val - 1) * 25}% - ${(question5Val - 3) * 6}px)` }}>
								<div className="range-slider__tooltip__label">{question5Val}</div>
								<div className="range-slider__tooltip__arrow"></div>
							</div>
						</span>
					</div>
				</div>
				<div>
					<div className='large-main-text'>Can you see yourself working sometime soon? Is working something you would like to do?</div>
					<div>
						<span className='range-slider__wrap range-slider__wrap--lg'>
							<input type='range'
								className={`${question6Val < 0 ? 'slider-hide' : 'slider-visible'} slider range-slider range-slider--lg range-slider--primary custom-range-sider`}
								size="lg" tooltip="hide" tooltipPlacement="top"
								value={question6Val}
								onChange={e => {
									setQuestion6Val(e.target.value);
								}}
								onClick={e => {
									// if (hideThumb) {
										//setHideThumb(false);
									// }
								}}
								min={1} max={5} />
							<div className={`range-slider__tooltip range-slider__tooltip--auto range-slider__tooltip--top`} style={{ left: `calc(${(question6Val - 1) * 25}% - ${(question6Val - 3) * 6}px)` }}>
								<div className="range-slider__tooltip__label">{question6Val}</div>
								<div className="range-slider__tooltip__arrow"></div>
							</div>
						</span>
					</div>
				</div>

			</DialogContent>
			<DialogActions>
				<Button onClick={submitAnswers} color="secondary">Submit Answers</Button>
				<Button onClick={e => setShowModal(false)}>Cancel</Button>
			</DialogActions>
		</Dialog>
	</TopToolbar>
}


const CaseShow = (props) => {
	const history = useHistory();

	const ExpandedPanel = (props) => (
		<Show {...props} component="div" title=" ">
			{props.record.carrier === 'sms' ?
	
				<SimpleShowLayout>
					<TextField source="recipient" />
					<TextField source="message" />
				</SimpleShowLayout> :
	
				<SimpleShowLayout>
					<TextField source="recipient" />
					<TextField source="subject" />
					<FunctionField label="Content" render={record => <div dangerouslySetInnerHTML={{ __html: record.content}}></div>} />
				</SimpleShowLayout>}
		</Show>
	);

	
	return <Show {...props} title={<CaseTitle />} actions={<ShowToolbar id={props.id} />} aside={<CaseShowAside />} component="div" >
		<SimpleShowLayout className="show-box show-left">
			<TextField source="job_seeker_id" />

			<TextField source="firstname" className="inline-form" style={{ minWidth: '5vw' }} />
			<TextField source="lastname" className="inline-form" />

			<div />

			<TextField source="phone" className="inline-form" style={{ minWidth: '5vw' }} />
			<EmailField source="email" className="inline-form" />

			<ReferenceField label="Assigned work coach" source="case_work_coach_id" reference="workcoaches">
				<FunctionField render={record => `${record.firstname} ${record.lastname} - ${record.username}`} />
			</ReferenceField>

			<ReferenceField label="Location" source="case_location_id" reference="locations" linkType="">
				<FunctionField render={record => `${record.name}`} />
			</ReferenceField>

			<TextField source="outcome" className="inline-form" />

			<DateField source="next_scheduled_meeting" locales="en-GB"/>

			<ReferenceManyField label="Survey responses" reference="survey-instance-history" target="id" sort={{ field: "published_at", order: "DESC" }}>
				<Datagrid rowClick={(e, basePath, record) => record.completed_at?history.push(`/survey-instances/${e}/show`):""}>
					<NumberField source="id" label="Survey ID" />
					{/* <FunctionField label="Status" render={record => record.completed_at ? <span style={{color: "#77BF43"}}>Completed</span> : <span style={{color: "#FF1744"}}>Not completed</span>} /> */}
					<NumberField source="motivation" emptyText="-"/>
					<NumberField source="confidence" emptyText="-"/>
					<DateField source="published_at" label="Sent date"  locales="en-GB" emptyText="-" options={{timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone}}/>
					<DateField source="completed_at" label="Completed date" locales="en-GB" options={{timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone}} emptyText={<span style={{color: "#FF1744"}}>Not completed</span>}/>
				</Datagrid>
			</ReferenceManyField>

			<ReferenceManyField label="Sent communications history" reference="communication-history" target="id">
				<Datagrid rowClick="expand" expand={<ExpandedPanel />}>
					<TextField source='type' emptyText="-" />
					<TextField source='carrier' emptyText="-" />
					{/* <TextField source='recipient' emptyText="-" /> */}
					{/* <TextField source="status" emptyText="-" /> */}
					<DateField source="scheduled_date" locales="en-GB" emptyText="-" label="Scheduled at" options={{timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone}}/>
					{/* <NumberField source="number_of_retries" /> */}
					{/* <PopUpTextField source="message" /> */}
					<FunctionField textAlign="right" label="Retries" render={record => record.status !== "success" ? record.number_of_retries : '-'} />
					{/* <FunctionField textAlign="center" source="actions" label="Action" render={record => record.status === 'failed' ? <Button color="primary">Resend</Button> : "-"} /> */}
				</Datagrid>
			</ReferenceManyField>

		</SimpleShowLayout>
	</Show>
}



const CaseSurveyToolbar = (props) => (
	<TopToolbar>
		<EditButton basePath="/cases" record={{ id: props.id }} />
		<ShowButton basePath='/cases' record={{ id: props.id }} />
	</TopToolbar>
)

// side note: case survey & case edit are basically the same, they're just divided into 2 for the sake of usability
const CaseSurvey = (props) => {
	let id = useParams().id;

	const [sendNow, setSendNow] = useState(false);
	const [selectedValue, setSelectedValue] = React.useState('a');

	const [defaultNum, setDefaultNum] = useState();
	const [isPristine, setIsPristine] = useState(true);

	const notify = useNotify();


	useEffect(() =>{
		getDefaultNum();
		
		
		if(sendNow)
		{
			document.getElementById('scheduled_date-helper-text').style.display = 'none';
			document.getElementById('scheduled_date-helper-text').previousElementSibling.classList.remove("MuiFilledInput-underline");
		}
	},[defaultNum,sendNow])

	const getDefaultNum =async() =>{
		const response = await dataProvider.get('settings');
		if(response.data[0].value == 'null')
		{
			setDefaultNum('');
		}
		else{
			setDefaultNum(response.data[0].value);
		}
	}

	const handleChange = (event) => {
	  setSelectedValue(event.target.value);
	};

	const submit = data => {
		dataProvider.update('schedule/cases', {
			id: id,
			data: {
				next_scheduled_meeting: moment.utc(moment(data.next_scheduled_meeting, moment_date_format).hour(23).minute(59).second(59)).format(moment_date_format),
				scheduled_date: sendNow ?
					moment.utc(new Date()).format(moment_date_format) :
					setDateDiff(data.next_scheduled_meeting, data.scheduled_date)
			}
		}).then(result => {
			if (result.data && !result.error) {
				window.location.href = `/#/cases/${id}/show`;
				notify('Updated');
			} else {
				notify('Error updating schedule, please try again', 'error')
			}
		});
	}

	const onSendNow = event => {
		handleChange(event); 
		setSendNow(true);
		// document.getElementById('scheduled_date').value = '0';
		setIsPristine(false);
	}

	const ScheduleToolbar = props => (
		<Toolbar {...props}>
			<SaveButton disabled={props.pristine && isPristine} />	
			<Button component={ Link } to="/cases" style = {{color:"blue",position:'absolute',right:'15px'}}>Cancel</Button>			
		</Toolbar>
	)

	return <SaveContextProvider value="cases">
		<Edit syncWithLocation id={id} resource="cases" title="Schedule survey" basePath="/cases" actions={<CaseSurveyToolbar id={id} />} save={submit}>
			<SimpleForm variant="outlined" save={submit} toolbar={<ScheduleToolbar />}>
				<div className='form-group'>
					<p style={{ minWidth: '150px', marginRight: '25px' }}>Meeting date</p>
					<DateInput source="next_scheduled_meeting" label="" validate={[required(), validateDateLimit]} variant="outlined" />
				</div>
				<div className='form-group'>
					<p style={{ minWidth: '150px', marginRight: '25px' }}>Send Survey</p>
					<div>
						<div style={{ display: 'flex' }}>
							<NumberInput className='numberInput' label="" key={sendNow} defaultValue={defaultNum} source="scheduled_date" variant="outlined" min={1} onChange={(event) => { handleChange(event); setSendNow(false) }} validate={!sendNow ? [required(),validateNumber] : undefined} />
							<p>days before the meeting</p>
						</div>
						<FormControlLabel control={<Radio name="send_now" inputProps={{ 'aria-label': 'true' }} checked={selectedValue === 'true'} value="true" onChange={onSendNow} />} label="Send now" />
					</div>
				</div>

			</SimpleForm>
		</Edit>

	</SaveContextProvider>
}



const CaseResend = props => {
	let id = useParams().id;
	let logId = useParams().logId;
	let carrier = useParams().carrier;

	const notify = useNotify();

	const [email, setEmail] = useState();
	const [phone, setPhone] = useState();

	const resendEmail = (id) => {
		dataProvider.post(`resend/email?log_ids=[[${id}]]`).then(res => res.data.message && notify("Email resent")).then(() => window.location.href = '/#/logs').catch(e => notify("Resend failed, please try again later", "error"))
	}

	const resendSms = (id) => {
		dataProvider.post(`resend/sms?log_ids=[[${id}]]`).then(res => res.data.message && notify("SMS resent")).then(() => window.location.href = '/#/logs').catch(e => notify("Resend failed, please try again later", "error"))
	}

	const submit = (data) => {
		dataProvider.put(`cases/${id}`, data)
			.then(data => !data.error && carrier === 'sms' ? resendSms(logId) : resendEmail(logId))
			.catch(() => notify("Cannot resend message, please try again", "error"))
	}

	const ResendToolbar = props => {
		const record = props.record;
		return (
		<Toolbar {...props}>
			<Button variant="contained" disabled={props.pristine} color="primary" onClick={() => submit({ ...record, email: email || record.email, phone: phone || record.phone })}>Save & Resend</Button>
			<Button component={ Link } to="/logs" style = {{color:"blue",position:'absolute',right:'15px'}}>Cancel</Button>	
		</Toolbar>
	)}

// record.carrier === 'sms' ? resendSms(record.log_ids) : resendEmail(record.log_ids)
	return <SaveContextProvider value="cases">
		<Edit syncWithLocation id={id} resource="cases" title="Resend message" basePath="/cases" actions={<CaseSurveyToolbar id={id} />}>
		<SimpleForm variant="outlined" toolbar={<ResendToolbar/>}>
			<div className='form-group'>
				<p style={{ minWidth: '150px', marginRight: '25px' }}>Email *</p>
				<TextInput source="email" label="" variant="outlined" formClassName="inline-form" validate={[required(), validateEmail()]} onChange={e => setEmail(e.target.value)} />
			</div>

			<div className='form-group'>
				<p style={{ minWidth: '150px', marginRight: '25px' }}>Mobile phone</p>
				<TextInput source="phone" label="" variant="outlined" formClassName="inline-form" validate={[validatePhone()]} onChange={e => setPhone(e.target.value)} />
			</div>
		</SimpleForm>
		</Edit>

	</SaveContextProvider>
}

export { CaseCreate, CaseEdit, CaseList, CaseShow, CaseSurvey, CaseResend };
