import React from 'react';
import AuthProvider from '../networking/AuthProvider';

// show this thing everywhere the user is not meant to access it
export default () => (
	<div className="text-center unauthorized">
		<div className="unauthorized-401">401</div>
		<div className="unauthorized-msg">You are not authorized for the selected action</div>
		<div className="unauthorized-redirect">Go back to&nbsp;
			<span className="unauthorized-redirect-link" onClick={() => {
				AuthProvider.logout();
				window.location.href = '/#/login'
			}}>login</span>
		</div>
	</div>
);
