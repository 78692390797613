// for options (separate tooltip config below since the functionality might be different between each screen)
const chartOptions = {
	plugins: {
		datalabels: {
		display: false,
		}
	},
	responsive: false,
	maintainAspectRatio: false,
	legend: {
		display: false,
	},
	scales: {
		yAxes: [{
			ticks: {
				beginAtZero: true,
				max: 16,
				min: 0,
				stepSize: 2,
			},
			scaleLabel: {
				display: true,
				fontSize: 16,
				fontColor: '#000',
				padding: 10,
				labelString: 'Motivation'
			},
			id:'y-axis-0'
		}],
		xAxes: [{
			ticks: {
				beginAtZero: true,
				max: 16,
				min: 0,
				stepSize: 2,
			},
			scaleLabel: {
				display: true,
				fontSize: 16,
				fontColor: '#000',
				padding: 10,
				labelString: 'Confidence'
			},
			id:'x-axis-0'
		}],
	}
}

// for dataset
const chartDatapoints = {
	fill: false,
	pointRadius: 8,
	pointHoverRadius: 10,
}

// for options.tooltips, tooltipConfig.callbacks is not implemented
const tooltipConfig = {
	displayColors: false,
	xPadding: 10,
	yPadding: 10,
	bodyFontSize: 16,
	bodyFontColor: '#222222',
	backgroundColor: '#FFFFFFDD',
	borderColor: '#222222',
	borderWidth: 1
}

export { chartOptions, chartDatapoints, tooltipConfig };
