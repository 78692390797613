import React, { useEffect, useState } from 'react';
import {
	Datagrid,
	TextField,
	FunctionField,
	EmailField,
	TopToolbar,
	CreateButton,
	ExportButton,
	ListContextProvider,
	useNotify,
} from 'react-admin';
import keyBy from 'lodash/keyBy';
import { Button, Card, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, TextField as MuiTextInput, Typography } from '@material-ui/core';
import dataProvider from '../networking/DataProvider';
import PublishIcon from '@material-ui/icons/Publish';
import Dropzone from 'react-dropzone';
import Papa from 'papaparse';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export default props => {
	const [showUpload, setShowUpload] = useState(true);
	const [showConfirm, setShowConfirm] = useState(false);
	const [showPreview, setShowPreview] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertDialogText, setAlertDialogText] = useState('');
	const [alertDialogTitle, setAlertDialogTitle] = useState('');
	const [showCancelConfirm, setShowCancelConfirmModal] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [grid, setGrid] = useState([]);
	const [gridPreview, setGridPreview] = useState([]);
	const [filename, setFilename] = useState({});
	const [filedata, setFiledata] = useState();

	const [permission, setPermission] = useState(3);

	const notify = useNotify();

	useEffect(() => setPermission(parseInt(localStorage.getItem('permissions'))), []);

	const submitFile = async () => {
		let data = await dataProvider.create('workcoaches', { data: { users: JSON.stringify(grid) } });
		data = data.data;
		if (data.error) {
			setShowPreview(false);
			setShowAlert(true);
			setAlertDialogTitle("Upload CSV failed");
			setAlertDialogText(<div>
				<b>{data.error.message}</b>
			</div>)
		} else {
			data = data.data;
			if (data.failed.length > 0) {
				setShowPreview(false);
				setShowAlert(true);
				setAlertDialogTitle("Some errors occured");

				setAlertDialogText(<div>
					<Typography><b>{data.success.length} row{data.success.length > 1 && "s"} </b>successfully created</Typography>
					<br />

					<Typography><b>{data.failed.length} row{data.failed.length > 1 && "s"} </b>were rejected:</Typography>
					<ul>
						{data.failed.filter((item, index) => index <= 5).map(item => <li>
							<b>Row {item.row}</b>:
							{item.message.length > 1 ? <ul>{item.message.map(i => <li>{i}</li>)}</ul> : <span> {item.message[0]}</span>}
						</li>)}
					</ul>

					{data.failed.length > 5 && <div>
						<Typography>{data.failed.length - 5} more row{data.failed.length > 6 && "s"} with errors not shown</Typography>
						<br />
					</div>}

					<Typography>You can export all failed rows to a CSV file below</Typography>

					<br />

					{/* <Typography><i>Note: If you use Excel, we recommend importing file into Excel rather than opening it directly to avoid Excel's auto format</i></Typography> */}
					{/* <Typography><i>Open excel, create blank worksheet, search "From text/CSV", choose the file, click "Transform Data", go to "Transform" tab and click "Use first row as header" then save</i></Typography> */}

					{/* <br /> */}
				</div>)
			}
			else {
				setShowModal(false);
				notify("Created");
				props.onUpload && props.onUpload();
			}
		}
		
	}

	const splitByCaps = text => text.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")

	const uploadFile = files => {
		setShowUpload(false);
		setFilename(files[0].name);

		Papa.parse(files[0], {
			skipEmptyLines: true,
			header: true,

			complete: results => {
				const errors = results.errors;
				if (errors.length > 0) {
					console.log(errors);
					setShowAlert(true);
					setAlertDialogTitle("Cannot parse CSV file")
					setAlertDialogText(<div>
						{errors.map(error => (<div>
							{/* <DialogContentText><b>Error type</b>: {splitByCaps(error.type)}</DialogContentText> */}
							<DialogContentText><b>Row {error.row + 2}</b>: {error.message}</DialogContentText>
						</div>
						))}
					</div>);

				} else {
					setFiledata(results.data);
					setShowConfirm(true);
				}
			}
		});
	}

	function validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	// used for processing coaches
	const processCoaches = () => {
		const keys = ['firstname', 'lastname', 'username', 'phone', 'employee_id', 'access_type'];
		const keyText = ['First Name', 'Last Name', 'Email', 'Phone', 'Work Coach ID', "Access Level"];
		let error = false;

		const processed = filedata
			.map(item => Object.fromEntries(Object.entries(item).map(field => [field[0].toLowerCase(), field[1].toString()])))
			.map(item => ({
				firstname: item["first name"],
				lastname: item["last name"],
				username: item["email"],
				phone: item["phone"],
				employee_id: item["employee id"],
				access_type: item["access level"]
			}))
			.map((item, index) => {
				// check if all required keys are not null or not, if it's null, reject the form and alert the job seeker that a field is missing in a specific case
				if (keys.find((i, idx) => {
					if (i in item === false || !item[i]) {
						error = true;
						setAlertDialogText(<span><b>Row {index + 2}</b> doesn't have any <b>{keyText[idx]}</b> field, upload cancelled</span>); // + 2 due to array starts from 0 + 1 header row
						return true;
					}
					else
						return false;
				})) {
				}

				// else if (!phoneUtil.isValidNumberForRegion(phoneUtil.parse(item.phone, 'GB'), 'GB'))
				else if (!/^\d{10}$/.test(item.phone)) {
					error = true;
					setAlertDialogText(<span><b>Row {index + 2}</b>: <b>{item.phone}</b> is not a valid Australian phone number format, it should be 10 digits long</span>);
				}

				else if (!validateEmail(item.username)) {
					error = true;
					setAlertDialogText(<span><b>Row {index + 2}</b>: <b>{item.username}</b> is not a valid email</span>);
				}

				else if (item.access_type < permission) {
					error = true;
					setAlertDialogText(<div>
						<Typography><b>Row {index + 2}</b>: You are not permitted to create an account with this access level</Typography>
						<br />
						<Typography><i>Your <b>level {permission}</b> account can only be used to create accounts with <b>level {permission} and above</b></i></Typography>
					</div>)
				}

				else {
					error = error || false;
					return item;
				}
			})

		if (!error) {
			setGrid(processed);
			setGridPreview(processed.filter((item, index) => index < 10));
			setShowPreview(true);
		} else {
			setAlertDialogTitle("Validate CSV file failed");
			setShowAlert(true);
		}
		setShowConfirm(false);
	}

	// called before the dialog opens to set everything up
	const onImport = () => {
		setShowModal(true);
		setShowUpload(true);
		setShowPreview(false);
		setShowConfirm(false);
		setShowAlert(false);
		setShowCancelConfirmModal(false);
		setGrid([]);
		setGridPreview([]);
	}

	const downloadCsvFormat = () => {
		download(Papa.unparse([["Employee ID", "First name", "Last name", "Email", "Phone", "Access level"]]), "Import coaches template.csv", "text/csv");
	}

	// Function to download data to a file
	function download(data, filename, type) {
		var file = new Blob([data], { type: type });
		if (window.navigator.msSaveOrOpenBlob) // IE10+
			window.navigator.msSaveOrOpenBlob(file, filename);
		else { // Others
			var a = document.createElement("a"),
				url = URL.createObjectURL(file);
			a.href = url;
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			setTimeout(function () {
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			}, 0);
		}
	}

	return permission < 3 && <TopToolbar className="toolbar">
		<CreateButton basePath={props.basePath} style={{ margin: '0 10px' }} />
		<Button color="primary" component="span" className="import-button" onClick={onImport}>
			<PublishIcon />
			IMPORT
		</Button>
		{/* <ExportButton /> */}

		<Dialog open={showModal} maxWidth='20vw'>
			{showUpload &&
				<div>
					<DialogTitle id="form-dialog-title">Import data</DialogTitle>
					<DialogContent>
						<Dropzone onDrop={acceptedFiles => uploadFile(acceptedFiles)} accept=".csv" multiple={false}>
							{({ getRootProps, getInputProps }) => (
								<section className='dropzone-area'>
									<div {...getRootProps()}>
										<input {...getInputProps()} />
										<div className='dropzone-input'>
											<h2>Choose a file or drag it here</h2>
											<p>Must be in .CSV format</p>
										</div>
									</div>
								</section>
							)}
						</Dropzone>

						<Button className="center-button" onClick={downloadCsvFormat} color="secondary">Download CSV template</Button>
					</DialogContent>
					<DialogActions>
						<Button onClick={e => setShowModal(false)}>Cancel</Button>
					</DialogActions>
				</div>
			}


			{showConfirm && <div>
				<DialogTitle>Uploaded file</DialogTitle>
				<DialogContent>
					{/* <DialogContentText><b>File uploaded:</b> {filename}</DialogContentText> */}

					<div className='filename-area'><InsertDriveFileIcon /> {filename}</div>

				</DialogContent>
				<DialogActions>
					<Button onClick={e => setShowModal(false)}>Cancel</Button>
					<Button color="secondary" onClick={() => { setShowUpload(true); setShowConfirm(false) }}>Retry</Button>
					<Button color="primary" onClick={() => processCoaches()}>Confirm</Button>
				</DialogActions>
			</div>}

			{showPreview &&
				<div>
					<DialogTitle id="form-dialog-title">Preview data</DialogTitle>
					<DialogContent>
						<DialogContentText>Previewing first {gridPreview.length} items. {grid.length} items uploaded</DialogContentText>
						<ListContextProvider value={{
							data: keyBy(gridPreview, 'employee_id'),
							ids: gridPreview.map(item => item === undefined ? '' : item.employee_id),
							basePath: "/workcoaches",
							resources: "workcoaches",
							total: gridPreview.length,
							page: 1,
							perPage: gridPreview.length,
							setPage: 1,
							currentSort: { field: 'id', order: 'ASC' },
						}}>
							<Datagrid>
								<TextField source="employee_id" />
								<FunctionField label="Name" render={record => `${record.firstname} ${record.lastname}`} />
								<TextField source="phone" />
								<EmailField source="username" label="Email" />
								<FunctionField label="Access level" render={record => record.access_type === "1" ? "GetPrepGo admin" : record.access_type === "2" ? "Workcoach admin" : "Workcoach"} />
							</Datagrid>
						</ListContextProvider>
					</DialogContent>
					<DialogActions>
						<Button onClick={e => { setShowCancelConfirmModal(true); setShowPreview(false); }}>Cancel</Button>
						<Button color="primary" onClick={submitFile}>Submit</Button>
					</DialogActions>
				</div>

			}

			{showCancelConfirm &&
				<div>
					<DialogTitle id="form-dialog-title">Confirm</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to cancel upload?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => { setShowPreview(true); setShowCancelConfirmModal(false) }}>No</Button>
						<Button color="primary" onClick={() => { setShowModal(false) }}>Yes</Button>
					</DialogActions>
				</div>
			}

			{showAlert &&
				<div>
					<DialogTitle id="form-dialog-title">An error occured</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{alertDialogText}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={e => setShowModal(false)}>Cancel</Button>
						<Button color="primary" onClick={() => { setShowAlert(false); setShowUpload(true) }}>Retry</Button>
					</DialogActions>
				</div>
			}
		</Dialog>
	</TopToolbar>
}