import React, { useState,useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useNotify, Notification } from 'react-admin';
import authProvider from '../networking/AuthProvider'

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: '#F34265',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		backgroundColor: '#F34265',
		color: 'white'
	},
}));


export default () => {
	const classes = useStyles();

	const [code, setCode] = useState();
	const [seconds, setSeconds] = React.useState(10);
	const [resendDisable,setResendDisable] = useState(true);

	const notify = useNotify();
	const apiUrl = process.env.REACT_APP_API_URL;

	useEffect(()=>{
		if (!localStorage.getItem("token")) {
			window.location.href = '/#/login';
			notify("Please log in first");
			return;
		}

		if (seconds > 0) {
			setResendDisable(true);
			setTimeout(() => setSeconds(seconds - 1), 1000);
			} else {
			setSeconds('');
			setResendDisable(false);
			}
	})

	const onSubmit = (e) => {
		e.preventDefault();
		authProvider.auth2fa(code)
			.then(() => {
				window.location.href = '/#/cases';
				notify("Login successfully");
			})
			.catch(() => notify("Invalid authentication code"))
	}

	const onResend= async(e) => {
		e.preventDefault();

		let body = new URLSearchParams();
		body.append('work_coach_id',localStorage.getItem('id'));
		await fetch(`${apiUrl}/auth/twoFactor`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: body
		}).then(setSeconds(10));
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Two-factor Authentication
        </Typography>
				<br />
				<Typography component="p" variant="p">
					Enter the code from your email
        </Typography>
				<form className={classes.form} onSubmit={onSubmit}>
					<div style={{display:'flex',justifyContent:'space-between'}}>
					<TextField
						variant="outlined"
						margin="normal"
						required
						id="2fa-code"
						label="Authentication Code"
						name="code"
						color='secondary'
						autoComplete="code"
						autoFocus
						style = {{width:'62%'}}
						onChange={e => setCode(e.target.value)}
					/>
					<Button
						variant="contained"
						color="primary"
						className={classes.submit}
						onClick = {onResend}
						disabled = {resendDisable?true:false}
					>
						Resend {seconds>0&&'('} {seconds} {seconds>0&&')'}
					</Button>
					</div>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color='secondary'
						className={classes.submit}
					>
						Submit
          			</Button>
					<Grid container>
						<Grid item xs>
							{/* <Link variant="body2">
								Forgot password?
              </Link> */}
						</Grid>
					</Grid>
				</form>
			</div>
			<Notification />
		</Container>
	);
}