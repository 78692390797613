import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import AuthProvider from '../networking/AuthProvider';
import { Link } from 'react-router-dom';


export default () => {
	const [verified, setVerified] = useState();

	useEffect(() => {
		const url = window.location.href;
		const code = url.toString().substring(url.indexOf("=")+1,url.toString().length);
		AuthProvider.authAccount(code).then(() => setVerified(true)).catch(() => setVerified(false));
	}, []);

	return (
		<div className='text-only-page'>
			{verified === undefined && <Typography>Verifying your account...</Typography>}
			{verified === false && <Typography>Cannot verify your account, or your account has already been verified</Typography>}
			{verified === true && <Typography>Your account has been verified</Typography>}
			<Typography>Go to <Link to='/login'>Login</Link></Typography>
		</div>
	);
}