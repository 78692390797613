import React, { useState } from 'react';
import {
	Edit,
	SimpleForm,
	TextInput,
	number,
	email,
	required,SaveButton,Toolbar, useNotify,useRedirect, PasswordInput
} from 'react-admin';
import { Button } from '@material-ui/core';

import { Link } from 'react-router-dom';

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const validatePhone = (phone) => {
	// try{
	// 	if (!phoneUtil.isValidNumberForRegion(phoneUtil.parse(phone, 'GB'), 'GB')) {
	// 		return 'Must be a valid UK mobile number';
	// 	}
	// 	return undefined;
	// }catch{
	// 	return 'Must be a valid UK mobile number';
	// }
	return /^\d{10}$/.test(phone) ? undefined : "Must be a valid phone number";
};

export const ProfileEdit = props => {
	var permissions = localStorage.getItem('permissions');
	const notify = useNotify();
	const redirect = useRedirect();

	const onSuccess = ({ data }) => {
		if(permissions<3)
		{
			redirect('/workcoaches');
		}
		else{
			redirect('/cases')
		}
		notify("Updated");
    };

	const validateForm = data => {
		console.log(data);
		if (data.password !== data.password_retype)
			return { password: "Passwords don't match", password_retype: "Passwords don't match" }
		return;
	}

	const EditToolbar = props => (
		<Toolbar {...props}>
	
			<SaveButton disabled={props.pristine}/>		
			<Button component={ Link } to="/cases" style = {{color:"blue",position:'absolute',right:'15px'}}>Cancel</Button>	
		</Toolbar>
	) 

	return(
	<Edit onSuccess={onSuccess} mutationMode="pessimistic" redirect={false} title="My profile" basePath="/workcoaches" resource="workcoaches" id={localStorage.getItem('id')}  undoable={false}>
		<SimpleForm  toolbar={<EditToolbar/>} variant="outlined">
		<div className='form-group'>
				<p style={{ minWidth: '150px', marginRight: '25px' }}>First name *</p>
				<TextInput source="firstname" label="" variant="outlined" formClassName="inline-form" validate={[required()]} />
			</div>
			<div className='form-group'>
				<p style={{ minWidth: '150px', marginRight: '25px' }}>Last name *</p>
				<TextInput source="lastname" label="" variant="outlined" formClassName="inline-form" validate={[required()]} />
			</div>
			<div className='form-group'>
				<p style={{ minWidth: '150px', marginRight: '25px' }}>Phone *</p>
				<TextInput source="phone" label="" variant="outlined" formClassName="inline-form" validate={[required(), validatePhone]} />
			</div>
			<div className='form-group'>
				<p style={{ minWidth: '150px', marginRight: '25px' }}>Email *</p>
				<TextInput source="username" label="" variant="outlined" formClassName="inline-form" validate={[required(), email()]} />
			</div>

			{/* <PasswordInput source="password" validate={[required()]} />
			<PasswordInput source="password_retype" validate={[required()]} /> */}
		</SimpleForm>
	</Edit>
	)

}