import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

const httpClient = fetchUtils.fetchJson;

const apiUrl = process.env.REACT_APP_API_URL;

// create header data
const createHeader = data => new Headers({
	...data,
	'Authorization': 'Bearer ' + JSON.parse(JSON.stringify(localStorage.getItem('token'))),
	'Content-Type': 'application/x-www-form-urlencoded'
});

// whether the parameter can be sent to the backend
const sendable = (param) => param && Object.keys(param).length

export default {
	// raw data provider functions, if you these, work with the backend devs to control what you're sending to the backend server
	// in these functions, set the format of the params before calling this function. this function will just encode the param and send straight to the server
	get: (resource, params) => {
		let url = `${apiUrl}/${resource}`;
		if (params) {
			url = `${apiUrl}/${resource}?${stringify(params)}`;
		}
		return httpClient(url, {
			headers: createHeader()
		}).then(({ json }) => json)
	},


	post: (resource, params) =>
		httpClient(`${apiUrl}/${resource}`, {
			method: 'POST',
			headers: createHeader(),
			body: new URLSearchParams(params),
		}).then(({ json }) => json),

	put: (resource, params) =>
		httpClient(`${apiUrl}/${resource}`, {
			method: 'PUT',
			headers: createHeader(),
			body: new URLSearchParams(params),
		}).then(({ json }) => json),


	// necessary data provider functions, don't modify these or you'll risk breaking the entire website
	getList: (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const save_filter = params.filter.save_filter;
		if (save_filter) {
			delete params.filter.save_filter;
			save_filter(params.filter);
		}

		const query = {
			_sort: JSON.stringify([field, order.toLowerCase()]),
			_range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
			_type: 'list',
			...sendable(params.filter) && { _filter: JSON.stringify(params.filter) },
		};
		const url = `${apiUrl}/${resource}?${stringify(query)}`;

		return httpClient(url, {
			headers: createHeader()
		}).then(({ json }) => Promise.resolve(json));
	},


	getOne: (resource, params) =>
		httpClient(`${apiUrl}/${resource}/${params.id}`, {
			headers: createHeader()
		}).then(({ json }) => json),


	getMany: (resource, params) => {
		const filter = Object.entries(params).filter(item => item[0] !== 'ids');

		const query = {
			_type: 'many',
			...sendable(params) && { _filter: JSON.stringify({ ...filter, id: params.ids }) },
		};
		const url = `${apiUrl}/${resource}?${stringify(query)}`;

		return httpClient(url, {
			headers: createHeader()
		}).then(({ json }) => json);
	},


	getManyReference: (resource, params) => {
		const { field, order } = typeof params.sort !== "undefined" ? params.sort : { field: '', order:''};
		const query = {
			_sort: JSON.stringify([field, order.toLowerCase()]),
			_type: 'reference',
			_filter: JSON.stringify({
				[params.target]: [params.id],
			})
			,
		};
		const url = `${apiUrl}/${resource}?${stringify(query)}`;
		
		return httpClient(url, {
			headers: createHeader()
		}).then(({ json }) => ({ ...json, total: json.data.length }));
	},


	update: (resource, params) =>
		httpClient(`${apiUrl}/${resource}/${params.id}`, {
			method: 'PUT',
			headers: createHeader(),
			body: new URLSearchParams(params.data),
		}).then(({ json }) => json),


	updateMany: (resource, params) => {
		const query = {
			filter: JSON.stringify({ id: params.ids }),
		};
		return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
			method: 'PUT',
			headers: createHeader(),
			body: new URLSearchParams(params.data),
		}).then(({ json }) => json);
	},


	create: (resource, params) =>
		httpClient(`${apiUrl}/${resource}`, {
			method: 'POST',
			headers: createHeader(),
			body: new URLSearchParams(params.data),
		}).then(({ json }) => ({ data: { id: 0, ...json } })),


	delete: (resource, params) =>
		httpClient(`${apiUrl}/${resource}/${params.id}`, {
			method: 'DELETE',
			headers: createHeader(),
		}).then(({ json }) => json),


	deleteMany: (resource, params) => {
		const query = {
			...sendable(params.filter) && { _filter: JSON.stringify({ id: params.ids }) },
		};
		return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
			method: 'DELETE',
			headers: createHeader(),
			body: new URLSearchParams(params.data),
		}).then(({ json }) => json);
	},
};