import React, { useEffect, useState } from 'react';
import {

	FunctionField,
	Show,
	SimpleShowLayout,
	ResourceContextProvider,
	Datagrid,
	Title,
	ReferenceField,
	List as RAList,
	ListContextProvider,
	ListGuesser,
	DateField
} from 'react-admin';
import { Line, Bar,Scatter,HorizontalBar } from 'react-chartjs-2'
import { chartOptions, chartDatapoints, tooltipConfig} from '../components/ChartConfig'
import dataProvider from '../networking/DataProvider'
import { Link } from "react-router-dom";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TextField, Typography } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import keyBy from 'lodash/keyBy';
import 'chartjs-plugin-datalabels';
import { debounce } from 'lodash';

function simpleStringify(object) {
	
	var simpleObject = {};
	for (var prop in object) {
		if (!object.hasOwnProperty(prop)) {
			continue;
		}
		if (typeof (object[prop]) == 'object') {
			continue;
		}
		if (typeof (object[prop]) == 'function') {
			continue;
		}
		simpleObject[prop] = object[prop];
	}
	return JSON.stringify(simpleObject); // returns cleaned up JSON
};


const StatisticsShow = (props) => {
	const [data, setData] = useState([]);//Data used for generating scatter graph
	const [useCase, setUseCase] = useState([]);//Data used for fetching users
	const [selectedUsers, setSelectedUsers] = useState([]);//Users with selected result
	const [res, setRes] = useState();

	// useEffect(() => {
	// 	fetchData();fetchUsers(2);
	// }, [])

	useEffect(() => {
		fetchStats(0);
	}, [])


	function queryString()
	{
		const hash = window.location.hash;
		const query = hash.substring(hash.indexOf("?") + 1);
		const queryString = require('query-string');
		var ids  = queryString.parse(query, {arrayFormat: 'index'});
		ids  = JSON.stringify(ids).replace(/['"{}]/g, '').replace(/[:]/g, '=');
		return ids;
	}

	const fetchUsers = async (value) => {
		// let res = await dataProvider.get('statistics?most_recent_only=true&' + queryString());
		let selected = res.data.groupByScore;
		// console.log(JSON.stringify(selected[useCase[value].x][useCase[value].y]));
		setSelectedUsers(selected[useCase[value].x][useCase[value].y]);
	}

	const unquotedStringfy = (object) =>
	{
		return JSON.parse(JSON.stringify(object));
	}
	
	const config = {
		tooltips: {
			callbacks: {
				label: (item, data) => {
					data = data.datasets[0].data;
					return [`Confidence: ${item.label}`, `Motivation: ${item.value}`, `Cases with selected result: ${data[item.index].count}`];
				},
			},
			...tooltipConfig
		}
	}

	// const labels = ['Quadrant 1','Quadrant 2','Quadrant 3','Quadrant 4'];
	// const barData = [{
	// 	labels : ['Quadrant 1','Quadrant 2','Quadrant 3','Quadrant 4'],
	// 	datasets:[{
	// 		label: '',
	// 		data: ['65', '59', '80', '81'],
	// 		backgroundColor: [
	// 		  '#70afcf',
	// 		  '#f1cc53',
	// 		  '#14292F',
	// 		  '#f37a7a',
	// 		],
	// 	}]

	// }]

	const barData = {
		labels : ['Independence','Excite','Ignite','Guide'],
		datasets: [
		{
			backgroundColor: [
			'#70afcf',
			'#f1cc53',
			'#14292F',
			'#f37a7a',
			],
		data: [ 
			useCase.filter(value=>{
			//divide the amount of use cases in particular quadrant by the total amount of use cases
			return value.x>=9.5 && value.y>9.5;
			}).reduce((sum, record) => sum + record.count,0)/(useCase.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)) * 100, 
			useCase.filter(value=>{
				return value.x>9.5 && value.y<=9.5;
				}).reduce((sum, record) => sum + record.count,0)/(useCase.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)) * 100, 
			useCase.filter(value=>{
				return value.x<=9.5 && value.y<9.5;
				}).reduce((sum, record) => sum + record.count,0)/(useCase.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)) * 100,  
			useCase.filter(value=>{
				return value.x<9.5 && value.y>=9.5;
				}).reduce((sum, record) => sum + record.count,0)/(useCase.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)) * 100
			],
			fill:false
		}
		]
	};


	const plugins = [{
		beforeDraw: (chartInstance, easing) => {
			const ctx = chartInstance.chart.ctx;
			const scaleX = chartInstance.scales['x-axis-0'];
			const scaleY = chartInstance.scales['y-axis-0'];
			ctx.beginPath();
			ctx.moveTo(scaleX.left,scaleY.bottom/2.3+5);
			ctx.lineTo(scaleX.right, scaleY.bottom/2.3+5);
			ctx.strokeStyle = 'grey';
			ctx.stroke();
			ctx.beginPath();
			ctx.moveTo(scaleX.right/1.633,scaleY.top);
			ctx.lineTo(scaleX.right/1.633, scaleY.bottom);
			ctx.strokeStyle = 'grey';
			ctx.stroke();
		}
	}];

	const fetchStats = (value) => dataProvider.get(`statistics?offset=${value}&case_ids=[${props.selected}]`).then(response => {
		setRes(response);
		let ds = [];

		Object.entries(response.data.groupByScore)
			.map(xField => Object.entries(xField[1])
				.map(yField => 
					parseInt(xField[0])&&parseInt(yField[0])&&ds.push({x: parseInt(xField[0]), y: parseInt(yField[0]), count: yField[1].length})
				));

		setData([{
			fill: true,
			pointRadius: (point) => 4 + point.dataset.data[point.dataIndex].count * 2,
			pointHoverRadius: (point) => 6 + point.dataset.data[point.dataIndex].count * 2,
			backgroundColor:'#77BF43',
			data: ds,
		}])

		setUseCase(ds);
		setSelectedUsers([])
	})

	const sendSearch = debounce(value => fetchStats(value), 300);

	const onSearch = (value) => {
		sendSearch(value);
	}

	return (
		<div>
			<form onSubmit={data => console.log(data)}>
				<div id='stats-dialog-input'>
					<TextField id='stats-dialog-input-field'
						size="small"
						type="number"
						variant="outlined"
						defaultValue={0}
						onChange={(event) => onSearch(event.target.value)} />
					<Typography id='stats-dialog-input-label'>Rounds before most recent survey</Typography>
				</div>
			</form>

		<ResourceContextProvider value="statistics">
			{/* <Title title="Cohort result" /> */}
			{/* <Show syncwithlocation="true" id="1" resource="statistics" basePath="/statistics" title="Report"> */}
				<div className='statistics-card'>
					{/* <FunctionField label=" " render={record => ( */}
					<div className='survey-response-container'>
						<div className='survey-response-container-left-half'>
							<Scatter
								onElementsClick={
									elems => elems.length > 0 && fetchUsers(elems[0]._index)
								}
								width={window.innerHeight * 0.65} height={window.innerHeight * 0.65}
								data={{ datasets: data }}
								options={{ ...chartOptions, ...config,plugins: {
									datalabels: {
									display: false,
									}
								} }}
								plugins={plugins}>
							</Scatter>
							
						</div>
						<div className='survey-response-container-right-half' style={{ minWidth: '18vw' }}>
						<HorizontalBar
								data={barData}
								width={100}
								height={50}
								options={
								{
									legend: {
										display: false,
									},
									scales:
									{
										xAxes: [{
											gridLines: {
											  display: false,
											},
											ticks: {
												display: false,
												beginAtZero: true,
												max:100
											}
										  }],
										  yAxes: [{
											gridLines: {
											  display: false,
											},
										  }]
									},
									plugins: {
										datalabels: {
											display: true,
											color: 'blue',
											anchor:'end',
											align: 'right',
											formatter: function(value, context) {
												// console.log(value);
												return useCase.length>0 && Math.round(value)  + '%';
												
											}
										}
									} 
								}}
								
								/>
						</div>
						<div className='survey-response-container-right-half' style={{ minWidth: '18vw' }}>
							{data && data[0] && <h4>{data[0].data.length} {data[0].data.length > 1 ? 'cases' : 'case'} with completed surveys</h4>}
							{/* <h4>Cases with selected result</h4> */}
							{selectedUsers.length > 0 && <h4>Motivation: {selectedUsers[0].motivation} - Confidence: {selectedUsers[0].confidence}</h4>}
							<ListContextProvider value={{
								data: keyBy(selectedUsers, 'case_id'),
								ids: selectedUsers.map(item => item.case_id),
								basePath: "/cases",
								resources: "cases",
								total: selectedUsers.length,
								page: 1,
								perPage: selectedUsers.length,
								setPage: 1,
								currentSort: { field: 'id', order: 'ASC' }
							}}>
								<Datagrid rowClick="show">
									<ReferenceField label="Case" source="case_id" reference="cases" link="show">
										<FunctionField render={record => `${record.firstname} ${record.lastname}`} />
									</ReferenceField>
									<DateField source="completed_at" locales="en-GB" />
								</Datagrid>
							</ListContextProvider>

						</div>
					</div>
					{/* )} /> */}
				</div>
			{/* </Show> */}
		</ResourceContextProvider>
		</div>
	)
}

export { StatisticsShow };
