/*
 * @Author: your name
 * @Date: 2021-04-19 16:06:50
 * @LastEditTime: 2021-04-19 17:40:35
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /admin/src/pages/Locations.js
 */
import { Button, Typography, useTheme } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import {
	List,
	Datagrid,
	TextField,
	TextInput,
	Filter,
	FunctionField,
	Show,
	SimpleShowLayout,
	NumberField,
	ArrayField,
	ReferenceArrayField,
	ReferenceField,
	EditButton,
	downloadCSV,
	useNotify,
	DateField,
	ListContextProvider,
	BulkExportButton,
	useRedirect,
	SaveContextProvider,
	Create,
	SimpleForm,
	Toolbar,
	SaveButton,
	required,
	Edit,
	useRefresh,
	DeleteButton,
	CreateButton,
    MenuItemLink,
    useListContext,
    CardActions,
} from 'react-admin';
import DateFilter from '../components/DateFilter';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DataProvider from '../networking/DataProvider';
import Papa from 'papaparse';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReplayIcon from '@material-ui/icons/Replay';
import ListToolbar from '../components/LocationsToolbar';
import Container from '@material-ui/core/Container';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';

const LateSurveyList = (props) => {

	const Empty = props => (
		<div >
			<Typography class="location-title" component="h2" variant="h5">Survey Reminders</Typography>
			<Typography variant="h6" paragraph>
            	No incomplete surveys with reminders
        	</Typography>
		</div>
		
			
	);

    const MenuList = (props) => {
        const { data } = useListContext();
        return (
            <Datagrid rowClick="show">
				<FunctionField label="Name" render={record => `${record.firstname} ${record.lastname}`} />
				<DateField source="date_created" locales="en-GB" label="Last meeting date" />
            </Datagrid>
        );
    }

    const NoneActions = props => (
		<Typography class="location-title" component="h2" variant="h5">Survey Reminders</Typography>
    );

    const NonePagination = props => (
        ''
    );

	return (
		<List basePath="/cases" title=' ' sort={{field:'created_at', order: 'DESC'}} 
			bulkActionButtons={false} empty={<Empty/>} emptyWhileLoading
			actions={<NoneActions />}
			toolbar={<NonePagination />}
			filter={{ coach_id: localStorage.getItem('id') }}
			className={'survey-reminder'}
        >
            <MenuList />
		</List>)
};
export { LateSurveyList };