import React, { useEffect, useState, Fragment, cloneElement } from 'react';
import {
	Datagrid,
	TextField,
	FunctionField,
	EmailField,
	TopToolbar,
	CreateButton,
	ExportButton,
	DateField,
	ListContextProvider,
	useNotify,
	ReferenceField,
	useListContext,
	Button as RaButton
} from 'react-admin';
import keyBy from 'lodash/keyBy';
import { Button, Card, Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText, TextField as MuiTextInput, FormHelperText, Typography } from '@material-ui/core';
import dataProvider from '../networking/DataProvider';
import moment from 'moment';
import PublishIcon from '@material-ui/icons/Publish';
import Dropzone from 'react-dropzone';
import Papa from 'papaparse';
import { Link } from 'react-router-dom'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ShowChartIcon from '@material-ui/icons/ShowChart';

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

const moment_date_format = "YYYY-MM-DD hh:mm:ss";
const uk_date_format = "DD-MM-YYYY";

// set new date based on specific date - offset
const setDateDiff = (date, offset) => {
	let now = moment();
	let ndate = moment(date, uk_date_format);
	if (!ndate.isValid())
		return `Invalid date ${date}`
	ndate = ndate.hour(now.hour()).minute(now.minute()).second(now.second()).toDate();
	ndate.setDate(ndate.getDate() - offset);
	return moment.utc(moment(ndate)).format(moment_date_format);
}

export default props => {
	const [showUpload, setShowUpload] = useState(true);
	const [showConfirm, setShowConfirm] = useState(false);
	const [showPreview, setShowPreview] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertDialogText, setAlertDialogText] = useState('');
	const [alertDialogTitle, setAlertDialogTitle] = useState('');
	const [showCancelConfirm, setShowCancelConfirmModal] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [settings, setSettings] = useState();
	const [grid, setGrid] = useState([]);
	const [gridPreview, setGridPreview] = useState([]);
	const [coaches, setCoaches] = useState([]);
	const [filename, setFilename] = useState({});
	const [filedata, setFiledata] = useState();

	const notify = useNotify();

	const {
		className,
		exporter,
		filters,
		maxResults,
		...rest
	} = props;

	const {
		currentSort,
		resource,
		displayedFilters,
		filterValues,
		hasCreate,
		basePath,
		selectedIds,
		showFilter,
		total,
	} = useListContext();

	useEffect(() => {
		getSettings();
	}, [])

	const getSettings = async () => {
		const data = await dataProvider.get('settings');
		data.data[0].value && setSettings(data.data[0].value);
	}

	// Function to download data to a file
	function download(data, filename, type) {
		var file = new Blob([data], { type: type });
		if (window.navigator.msSaveOrOpenBlob) // IE10+
			window.navigator.msSaveOrOpenBlob(file, filename);
		else { // Others
			var a = document.createElement("a"),
				url = URL.createObjectURL(file);
			a.href = url;
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			setTimeout(function () {
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			}, 0);
		}
	}

	const exportFailed = (failed) => {
		// hidden easter egg ??????
		// console.log("Hi, if you see the Excel importing tutorial in the import cases dialog, then that is because I (the dev) opened the CSV file and had no idea what the heck was going on with the formatting, hence I put in the tutorial. You're welcome")

		console.log(failed);
		console.log(grid);

		download(Papa.unparse(
			failed.map(i => {
				const row = grid.find((item, index) => i.row - 2 == index) // 1 file header row + index starts from 0
				return row ? {
					"Job seeker ID": row.job_seeker_id,
					"First name": row.firstname,
					"Last name": row.lastname,
					"Email": row.email,
					"Phone": row.phone,
					"Work coach email": row.case_work_coach_username,
					"Next meeting": moment(row.next_scheduled_meeting, moment_date_format).format(uk_date_format),
					"Reason for rejection (For reference only)": i.message.flatMap(msg => `${msg};   `)
				} : {};
			}
			),
			{ header: true }), "Rejected rows.csv");
	}

	const submitFile = async () => {
		let data = await dataProvider.create('cases', { data: { jobseekers: JSON.stringify(grid) } });
		data = data.data;
		if (data.error) {
			setShowPreview(false);
			setShowAlert(true);
			setAlertDialogTitle("Some errors occured");
			setAlertDialogText(<div>
				<Typography><b>Duplicate or invalid data found, upload rejected</b>: {data.error.message}</Typography>
			</div>)
			return;
		}

		data = data.data;
		if (data.failed.length > 0) {
			setShowPreview(false);
			setShowAlert(true);
			setAlertDialogTitle("Some errors occured");

			setAlertDialogText(<div>
				<Typography><b>{data.success.length} row{data.success.length > 1 && "s"} </b>successfully created</Typography>
				<br />

				<Typography><b>{data.failed.length} row{data.failed.length > 1 && "s"} </b>were rejected:</Typography>
				<ul>
					{data.failed.filter((item, index) => index <= 5).map(item => <li>
						<b>Row {item.row}</b>:
						{item.message.length > 1 ? <ul>{item.message.map(i => <li>{i}</li>)}</ul> : <span> {item.message[0]}</span>}
					</li>)}
				</ul>

				{data.failed.length > 5 && <div>
					<Typography>{data.failed.length - 5} more row{data.failed.length > 6 && "s"} with errors not shown</Typography>
					<br />
				</div>}

				<Typography>You can export all failed rows to a CSV file below</Typography>

				<br />

				{/* <Typography><i>Note: If you use Excel, we recommend importing file into Excel rather than opening it directly to avoid Excel's auto format</i></Typography> */}
				{/* <Typography><i>Open excel, create blank worksheet, search "From text/CSV", choose the file, click "Transform Data", go to "Transform" tab and click "Use first row as header" then save</i></Typography> */}

				{/* <br /> */}

				<Button color="secondary" style={{ margin: "auto", display: "block" }} onClick={() => exportFailed(data.failed)}>Export rejected rows</Button>
			</div>)
		}
		else {
			setShowModal(false);
			notify("Created");
			props.onUpload && props.onUpload();
		}
	}

	const splitByCaps = text => text.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")

	const uploadFile = files => {
		setShowUpload(false);
		setFilename(files[0].name);

		Papa.parse(files[0], {
			skipEmptyLines: true,
			header: true,

			complete: results => {
				const errors = results.errors;
				if (errors.length > 0) {
					console.log(errors);
					setShowAlert(true);
					setAlertDialogTitle("Cannot parse CSV file")
					setAlertDialogText(<div>
						{errors.map(error => (<div>
							{/* <DialogContentText><b>Error type</b>: {splitByCaps(error.type)}</DialogContentText> */}
							<DialogContentText><b>Row {error.row + 2}</b>: {error.message}</DialogContentText>
						</div>
						))}
					</div>);

				} else {
					// dataProvider.getList('workcoaches', { pagination: { page: 0, perPage: 1000 }, sort: { order: 'ASC', field: 'id' }, filter: {} }).then(data => {
					// 	data.data && setCoaches(data.data);
					// })
					setFiledata(results.data);
					setShowConfirm(true);
				}
			}
		});
	}

	function validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	const validateDateLimit = data => moment(data, moment_date_format).toDate().getTime() - new Date().getTime() > 0


	// used for processing cases
	const processCases = () => {
		const keys = ['firstname', 'lastname', 'email', 'phone', 'job_seeker_id', 'case_work_coach_username']; // small pitfall of this implementation: it cannot check for next_scheduled_meeting (can workaround it, but meh)
		const keyText = ['First name', 'Last name', 'Email', 'Phone', 'Job Seeker ID', 'Work coach email'];
		let error = false;

		const processed = filedata
			.map(item => Object.fromEntries(Object.entries(item).map(field => [field[0].toLowerCase(), field[1].toString()])))
			.map(item => ({
				firstname: item["first name"],
				lastname: item["last name"],
				email: item["email"],
				phone: item["phone"],
				job_seeker_id: item["job seeker id"],
				case_work_coach_username: item["work coach email"],
				next_scheduled_meeting: moment.utc(moment(item["next meeting"], uk_date_format).hour(23).minute(59).second(59)).format(moment_date_format),
				scheduled_date: setDateDiff(item["next meeting"], settings)
			}))
			.map((item, index) => {
				// check if all required keys are not null or not, if it's null, reject the form and alert the job seeker that a field is missing in a specific case
				if (keys.find((i, idx) => {
					if ((i in item === false || !item[i]) && keyText[idx] != "Phone") {
						error = true;
						setAlertDialogText(<span><b>Row {index + 2}</b> doesn't have any <b>{keyText[idx]}</b> field, upload cancelled</span>); // + 2 due to array starts from 0 + 1 header row
						return true;
					}
					else
						return false;
				})) {
				}

				// else if (!phoneUtil.isValidNumberForRegion(phoneUtil.parse(item.phone, 'GB'), 'GB'))
				else if (!/^\d{10}$/.test(item.phone) && item.phone.length > 0) {
					error = true;
					setAlertDialogText(<span><b>Row {index + 2}</b>: <b>{item.phone}</b> is not a valid Australian phone number format, it should be 10 digits long</span>);
				}
				else if (!validateEmail(item.email) ) {
					error = true;
					setAlertDialogText(<span><b>Row {index + 2}</b>: <b>{item.email}</b> is not a valid email</span>);
				}
				else if (!validateEmail(item.case_work_coach_username)) {
					error = true;
					setAlertDialogText(<span><b>Row {index + 2}</b>: <b>{item.case_work_coach_username}</b> is not a valid email</span>);
				}
				else if (item.scheduled_date.includes("Invalid date") || item.next_scheduled_meeting.includes("Invalid date")) {
					error = true;
					setAlertDialogText(<span><b>Row {index + 2}</b>: <b>{item.scheduled_date.substring("Invalid date".length)}</b> is not a valid date</span>)
				}
				else if (!validateDateLimit(item.next_scheduled_meeting)) {
					error = true;
					setAlertDialogText(<span><b>Row {index + 2}</b>: Next scheduled meeting on <b>{moment(item.next_scheduled_meeting, moment_date_format).format(uk_date_format)}</b> cannot be a date before today</span>)
				}
				else {
					error = error || false;
					return item;
					// let coach = coaches.find(c => c.username === item.case_work_coach_id);
					// if (coach) {
					// 	item.case_work_coach_id = coach.id;
					// 	return item;
					// } else {
					// 	error = true;
					// 	setAlertDialogText(<span><b>Row {index + 2}</b>: No workcoach with email <b>{item.case_work_coach_id}</b> found</span>);
					// }
				}
				console.log(item);
				return {};
			})

		if (!error) {
			setGrid(processed);
			setGridPreview(processed.filter((item, index) => index < 10));
			setShowPreview(true);
		} else {
			setAlertDialogTitle("Validate CSV file failed");
			setShowAlert(true);
		}
		setShowConfirm(false);
	}

	// called before the dialog opens to set everything up
	const onImport = () => {
		setShowModal(true);
		setShowUpload(true);
		setShowPreview(false);
		setShowConfirm(false);
		setShowAlert(false);
		setShowCancelConfirmModal(false);
		setGrid([]);
		setGridPreview([]);
	}

	const downloadCsvFormat = () => {
		download(Papa.unparse([["Job seeker ID", "First name", "Last name", "Email", "Phone", "Work coach email", "Next meeting"]]), 'Import cases template.csv', "text/csv");
	}

	const SelectAllButton = props => {
		const onClick = () => {
			dataProvider.get('cases', { _filter: JSON.stringify(props.savedFilters), _sort: JSON.stringify(["id", "asc"]), _range: JSON.stringify([0, 10000000]) })
				.then(data => props.handler(data.data.map(item => item.id)))
		}

		return <span>
			<RaButton label="Show statistics" onClick={onClick} style={{ marginLeft: '10px' }}>
				<ShowChartIcon></ShowChartIcon>
			</RaButton>
		</span>
	}

	return <TopToolbar className="toolbar">
		{filters && cloneElement(filters, {
			resource,
			showFilter,
			displayedFilters,
			filterValues,
			context: 'button',
		})}
		<SelectAllButton {...props} />
		<CreateButton basePath={props.basePath} style={{ margin: '0 10px' }} />
		{/* <ImportButton {...props} {...importConfig} /> */}
		<Button color="primary" component="span" className="import-button" onClick={onImport}>
			<PublishIcon />
			IMPORT
		</Button>
		{/* <ExportButton /> */}

		<Dialog open={showModal} maxWidth='20vw'>
			{showUpload &&
				<div>
					<DialogTitle id="form-dialog-title">Import data</DialogTitle>
					<DialogContent>
						<Dropzone onDrop={acceptedFiles => uploadFile(acceptedFiles)} accept=".csv" multiple={false}>
							{({ getRootProps, getInputProps }) => (
								<section className='dropzone-area'>
									<div {...getRootProps()}>
										<input {...getInputProps()} />
										<div className='dropzone-input'>
											<h2>Choose a file or drag it here</h2>
											<p>Must be in .CSV format</p>
										</div>
									</div>
								</section>
							)}
						</Dropzone>

						<div className='form-group import-dialog-text-input'>
							<p style={{ minWidth: '150px', marginRight: '0.5em' }}>Number of days to send out survey in advanced before first meeting:</p>
							<MuiTextInput variant="outlined" className="import-dialog-text-inputbox" size="small" type="number" defaultValue={settings} onChange={e => setSettings(e.target.value)} />
							<p style={{ marginLeft: '0.5em' }}>days</p>
						</div>

						<Button className="center-button" onClick={downloadCsvFormat} color="secondary">Download CSV template</Button>
					</DialogContent>
					<DialogActions>
						<Button onClick={e => setShowModal(false)}>Cancel</Button>
					</DialogActions>
				</div>}

			{showConfirm && <div>
				<DialogTitle>Uploaded file</DialogTitle>
				<DialogContent>
					{/* <DialogContentText><b>File uploaded:</b> {filename}</DialogContentText> */}

					<div className='filename-area'><InsertDriveFileIcon /> {filename}</div>

					<div className='form-group import-dialog-text-input'>
						<p style={{ minWidth: '150px', marginRight: '0.5em' }}>Number of days to send out survey in advanced before first meeting:</p>
						<MuiTextInput variant="outlined" label="" className="import-dialog-text-inputbox" size="small" type="number" defaultValue={settings} onChange={e => setSettings(e.target.value)} id='upload-modal'
							error={!settings || settings < 0} helperText={settings < 0 ? 'Must be a positive number' : !settings ? 'Required' : ''} />
						<p style={{ marginLeft: '0.5em' }}>days</p>
					</div>

				</DialogContent>
				<DialogActions>
					<Button onClick={e => setShowModal(false)}>Cancel</Button>
					<Button color="secondary" onClick={() => { setShowUpload(true); setShowConfirm(false) }}>Retry</Button>
					<Button color="primary" disabled={!settings || settings <= 0} onClick={(e) => { e.preventDefault(); processCases() }}>Confirm</Button>
				</DialogActions>
			</div>}

			{showPreview &&
				<div>
					<DialogTitle id="form-dialog-title">Preview data</DialogTitle>
					<DialogContent>
						<DialogContentText>Previewing first {gridPreview.length} items. {grid.length} items uploaded</DialogContentText>
						<ListContextProvider value={{
							data: keyBy(gridPreview, 'job_seeker_id'),
							ids: gridPreview.map(item => item.job_seeker_id),
							basePath: "/cases",
							resources: "cases",
							total: gridPreview.length,
							page: 1,
							perPage: gridPreview.length,
							setPage: 1,
							currentSort: { field: 'id', order: 'ASC' }
						}}>
							<Datagrid>
								<TextField source="job_seeker_id" label="Job seeker ID" />
								<FunctionField label="Full name" render={record => `${record.firstname} ${record.lastname}`} />
								<TextField source="phone" />
								<EmailField source="email" />
								<DateField source="next_scheduled_meeting" locales="en-GB" />
								<DateField source="scheduled_date" label="Send survey" locales="en-GB" />
								{/* <FunctionField render={record => {
									const coach = coaches.find(item => item.employee_id === record.case_work_coach_id);
									return coach ? <Link className='MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary' to={`/workcoaches/${coach.id}`}>{coach.firstname} {coach.lastname} - {coach.username}</Link> : <div>Coach not found</div>
								}} /> */}
								{/* <ReferenceField source="case_work_coach_user" reference="workcoaches">
									<FunctionField render={record => `${record.firstname} ${record.lastname} - ${record.username}`} />
								</ReferenceField> */}
								<EmailField source="case_work_coach_username" label="Linked work coach email" />
							</Datagrid>
						</ListContextProvider>
					</DialogContent>
					<DialogActions>
						<Button onClick={e => { setShowCancelConfirmModal(true); setShowPreview(false); }}>Cancel</Button>
						<Button color="primary" onClick={submitFile}>Submit</Button>
					</DialogActions>
				</div>

			}
			{showCancelConfirm &&
				<div>
					<DialogTitle id="form-dialog-title">Confirm</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to cancel upload?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => { setShowPreview(true); setShowCancelConfirmModal(false) }}>No</Button>
						<Button color="primary" onClick={() => { setShowModal(false) }}>Yes</Button>
					</DialogActions>
				</div>
			}

			{showAlert &&
				<div>
					<DialogTitle id="form-dialog-title">{alertDialogTitle}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{alertDialogText}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={e => setShowModal(false)}>Cancel</Button>
						<Button color="primary" onClick={() => { setShowAlert(false); setShowUpload(true) }}>Retry</Button>
					</DialogActions>
				</div>

			}

		</Dialog>
	</TopToolbar>
}