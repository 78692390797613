import React, { useEffect, useState } from 'react';
import {
	Datagrid,
	TextField,
	FunctionField,
	EmailField,
	TopToolbar,
	CreateButton,
	ExportButton,
	ListContextProvider,
	useNotify,
    Labeled
} from 'react-admin';
import { Button, Typography, useTheme } from '@material-ui/core';


export default props => {
    const [permission, setPermission] = useState(3);

	const notify = useNotify();

	useEffect(() => setPermission(parseInt(localStorage.getItem('permissions'))), []);

	return permission < 3 && <TopToolbar className="toolbar" style={{ width: "100%" }}>
		<Typography class="location-title" style={{ width: "100%", marginLeft:"18px", marginTop:"0px" }} component="h2" variant="h5">Locations</Typography>
		<CreateButton basePath={'locations'} style={{ margin: '0 10px' }} />
		{/* <ExportButton /> */}
	</TopToolbar>
}