import React,{Fragment} from 'react';
import {
	Create,
	SimpleForm,
	TextInput,
	ArrayInput,
	SimpleFormIterator,
	Edit,
	List,
	Datagrid,
	TextField,
	FunctionField,
	Show,
	SimpleShowLayout,
	NumberField,
	ReferenceArrayField,
	EmailField,
	AutocompleteInput,
	SelectInput,
	ReferenceInput,
	CreateButton,
	ExportButton,
	required,
	email,
	number,
	Filter,
	TopToolbar,
	NumberInput,
	regex,
	useRedirect,
	useNotify,
	useRefresh,
	SaveButton,
	Toolbar,
	useRecordContext,
	BooleanField
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import Unauthorized from '../components/Unauthorized'
import { ImportButton } from 'react-admin-import-csv';
import ListToolbar from '../components/CoachesToolbar';
import dataProvider from '../networking/DataProvider';


const CoachTitle = ({ record }) => (
	<span>{record.firstname} {record.lastname}</span>
)

// @see case.js
const fieldTransform = (text) => text.toLowerCase() === 'id' ? 'id' : text.split(/(?=[A-Z])/).join(' ').replace(/ +/g, "_").toLowerCase()


const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
const validatePhone = (phone,allValues) => {
	// try{
	// 	if (!phoneUtil.isValidNumberForRegion(phoneUtil.parse(phone, 'GB'), 'GB')) {
	// 		return 'Must be a valid UK mobile number';
	// 	}
	// 	return undefined;
	// }catch{
	// 	return 'Must be a valid UK mobile number';
	// }
	return /^\d{10}$/.test(phone) ? undefined : "Must be a valid phone number";
};

const CoachCreate = ({ permissions, ...props }) => {
	const notify = useNotify();
	const redirect = useRedirect();
	const submit = data => ({
		users: JSON.stringify([{
			...data,
		}])
	})

	function success(data,basePath)
	{
		data.data.error && notify(data.data.error.message, "error");
		if (data.data.data.success) {
			notify("Created successfully");
			redirect(`/workcoaches/${data.data.data.success[0].id}`);
		}
	}

	// return permissions === 'admin' ? (
	return (
		<Create {...props} onSuccess = {success} title={<span>Create new work coach</span>} transform={submit} >
			<SimpleForm variant="outlined" toolbar={<BottomToolbar/>}>
				<div className='form-group'>
					<p style={{ minWidth: '150px', marginRight: '25px' }}>Workcoach ID *</p>
					<TextInput source="employee_id" label="" validate={[required()]} variant="outlined" />
				</div>

				<div className='form-group'>
					<p style={{ minWidth: '150px', marginRight: '25px' }}>First name *</p>
					<TextInput source="firstname" label="" variant="outlined" formClassName="inline-form" validate={[required()]} />
				</div>

				<div className='form-group'>
					<p style={{ minWidth: '150px', marginRight: '25px' }}>Last name *</p>
					<TextInput source="lastname" label="" variant="outlined" formClassName="inline-form" validate={[required()]} />
				</div>

				<div />

				<div className='form-group'>
					<p style={{ minWidth: '150px', marginRight: '25px' }}>Email *</p>
					<TextInput source="username" label="" variant="outlined" formClassName="inline-form" validate={[required(), email()]} />
				</div>

				<div className='form-group'>
					<p style={{ minWidth: '150px', marginRight: '25px' }}>Mobile phone *</p>
					<TextInput source="phone" label="" variant="outlined" formClassName="inline-form" validate={[required(), validatePhone]} />
				</div>

				<div className='form-group'>
					<p style={{ minWidth: '150px', marginRight: '25px' }}>Access level *</p>
					<SelectInput source="access_type" variant="outlined" label="" choices={[
						{...permissions === 1 && { id: 1, name: 'GetPrepGo admin' }},
						{ id: 2, name: 'Work coach admin' },
						{ id: 3, name: 'Work coach' },
					]} validate={[required()]} />
				</div>

			</SimpleForm>
		</Create>
	)
}

const BottomToolbar = props => (
    <Toolbar {...props}>
		<SaveButton disabled={props.pristine} />		
		<Button component={ Link } to="/workcoaches" style = {{color:"blue",position:'absolute',right:'15px'}}>Cancel</Button>		
	</Toolbar>  
) 
	



const CoachEdit = ({ permissions, ...props }) => {
	const BottomToolbarEdit = ({ permissions, ...props }) => {
		const refresh = useRefresh();
		const redirect = useRedirect();
		const record = useRecordContext();

	
		function deleteCoach () {
			const response = dataProvider.get('workcoaches_delete/'+record.record.id)
				.then(result => {
					redirect(`/workcoaches/`);
			});
		}
	
		return (<Toolbar {...props}>
			<SaveButton disabled={props.pristine} />		
			
			<Button component={ Link } to="/workcoaches" style = {{color:"blue",position:'absolute',right:'15px'}}>Cancel</Button>	
			<Button onClick={deleteCoach} color="secondary" style = {{position:'absolute',right:'100px'}}>Delete Coach</Button>	
		</Toolbar>
	)};

	return (<Edit {...props} title={<CoachTitle />}>
		<SimpleForm variant="outlined" toolbar={<BottomToolbarEdit/>}>
				<div className='form-group'>
					<p style={{ minWidth: '150px', marginRight: '25px' }}>Workcoach ID *</p>
					<TextInput source="employee_id" label="" validate={[required()]} variant="outlined" />
				</div>

				<div className='form-group'>
					<p style={{ minWidth: '150px', marginRight: '25px' }}>First name *</p>
					<TextInput source="firstname" label="" variant="outlined" formClassName="inline-form" validate={[required()]} />
				</div>

				<div className='form-group'>
					<p style={{ minWidth: '150px', marginRight: '25px' }}>Last name *</p>
					<TextInput source="lastname" label="" variant="outlined" formClassName="inline-form" validate={[required()]} />
				</div>

				<div />

				<div className='form-group'>
					<p style={{ minWidth: '150px', marginRight: '25px' }}>Email *</p>
					<TextInput source="username" label="" variant="outlined" formClassName="inline-form" validate={[required(), email()]} />
				</div>

				<div className='form-group'>
					<p style={{ minWidth: '150px', marginRight: '25px' }}>Mobile phone *</p>
					<TextInput source="phone" label="" variant="outlined" formClassName="inline-form" validate={[required(), validatePhone]} />
				</div>

				<div className='form-group'>
					<p style={{ minWidth: '150px', marginRight: '25px' }}>Access level *</p>
					<SelectInput source="access_type" variant="outlined" label="" choices={[
						{...permissions === 1 && { id: 1, name: 'GetPrepGo admin' }},
						{ id: 2, name: 'Work coach admin' },
						{ id: 3, name: 'Work coach' },
					]} validate={[required()]} />
				</div>

				<div className='form-group'>
					<p style={{ minWidth: '150px', marginRight: '25px' }}>Is Deleted</p>
					<TextInput source="deleted_status" label="" variant="outlined" formClassName="inline-form" disabled/>
				</div>
		</SimpleForm>
	</Edit>);
}

const CoachSearch = props => (
	<Filter {...props}>
		<TextInput label="Search" source="search" alwaysOn resettable/>
	</Filter>
);

const CoachList = ({ permissions, ...props }) => {
	const refresh = useRefresh();
	
	return (<List filters={<CoachSearch />} bulkActionButtons={false} actions={<ListToolbar onUpload={() => refresh()} />} {...props}>
		<Datagrid rowClick={(id, basePath, record) => { return permissions <= record.access_type ? "edit" : ""}}>
			<TextField source="employee_id" />
			<FunctionField label="Name" render={record => `${record.firstname} ${record.lastname}`} />
			<EmailField source="username" label="Email" />
			<TextField source="phone" />
			<FunctionField label="Role" render={record => record.access_type === 1 ? 'GetPrepGo admin' : record.access_type === 2 ? 'Work coach admin' : 'Work coach'} />
		</Datagrid>
	</List>
)};


const CoachShow = ({ permissions, ...props }) => (
	<Show {...props} title={<CoachTitle />}>
		<SimpleShowLayout>
			<TextField source="employee_id" />
			<FunctionField label="Name" render={record => `${record.firstname} ${record.lastname}`} />
			<EmailField source="username" />
			<TextField source="phone" />
			<FunctionField label="Role" render={record => record.access_type === 1 ? 'GetPrepGo admin' : record.access_type === 2 ? 'Work coach admin' : 'Work coach'} />
		</SimpleShowLayout>
	</Show>
)


export { CoachCreate, CoachEdit, CoachList, CoachShow };