import React from 'react';
import { AppBar, UserMenu, MenuItemLink, Resource, LoadingIndicator } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import {LateSurveyList} from './LateSurveys';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';

const User = props => {
	return (
		<UserMenu {...props}>
			<MenuItemLink
				to="/profile"
				primaryText="Profile"
				leftIcon={<SettingsIcon />}
				onClick={props.onClick}
				sidebarIsOpen
			/>
		</UserMenu>
	)
};

const Notifications = props => {
	return (
		<div style={{marginLeft:"auto"}}>
			<UserMenu {...props} primaryText="ProfileTest" leftIcon={<AssignmentLateIcon/>} onClick={props.onClick}>
				<Resource name='cases' list={LateSurveyList} /> 
			</UserMenu>
		</div>
	)
};

export default props => (
	<AppBar {...props} userMenu={<User />} >
	</AppBar>
);