// in src/Menu.js
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { DashboardMenuItem, MenuItemLink, getResources, Sidebar } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import { Redirect } from 'react-router-dom'

const Menu = ({ onMenuClick, logout }) => {
	const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
	const open = useSelector(state => state.admin.ui.sidebarOpen);
	const resources = useSelector(getResources);

	const textTransform = (string) => {
		string = string.replace(/([a-z](?=[A-Z]))/g, '$1 ');
		string = string.replace('_', ' ');
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const pages = ["cases", "survey-instances", "logs", "workcoaches", "settings"];

	return (
		<div id="left-menu-bar">
			{/* <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} /> */}
			{pages
				.map(page => {
					const resource = resources.find(r => r.name === page);
					return resource && <MenuItemLink
						key={page}
						to={`/${page}`}
						primaryText={
							(resource.options && resource.options.label) || textTransform(resource.name)
						}
						leftIcon={
							resource.icon ? <resource.icon /> : <DefaultIcon />
						}
						onClick={onMenuClick}
						sidebarIsOpen={open}
					/>
				})}
			{isXSmall && logout}
		</div>
	);
};

export default Menu;