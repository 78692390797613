/*
 * @Author: your name
 * @Date: 2021-04-19 16:06:50
 * @LastEditTime: 2021-04-19 17:40:35
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /admin/src/pages/Locations.js
 */
import { Button, Typography, useTheme } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import {
	List,
	Datagrid,
	TextField,
	TextInput,
	Filter,
	FunctionField,
	Show,
	SimpleShowLayout,
	NumberField,
	ArrayField,
	ReferenceArrayField,
	ReferenceField,
	EditButton,
	downloadCSV,
	useNotify,
	DateField,
	ListContextProvider,
	BulkExportButton,
	useRedirect,
	SaveContextProvider,
	Create,
	SimpleForm,
	Toolbar,
	SaveButton,
	required,
	Edit,
	useRefresh,
	DeleteButton,
	CreateButton
} from 'react-admin';
import DateFilter from '../components/DateFilter';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DataProvider from '../networking/DataProvider';
import Papa from 'papaparse';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReplayIcon from '@material-ui/icons/Replay';
import ListToolbar from '../components/LocationsToolbar';
import Container from '@material-ui/core/Container';
import AddIcon from '@material-ui/icons/Add';


const LocationCreate = (props) => {
    const redirect = useRedirect();

	const notify = useNotify();
		
	const submit = data => {
		DataProvider.create('locations', {
			data: {name: data.name}
		})
		.then(res => res.data)
		.then(data => {
			if (data.error)
				notify(data.error.message, "error");
			else {
				notify("Created successfully");
				redirect(`/settings`)
			}
		})
	};

	function success(data,basePath)
	{
		redirect(`/cases/${data.data.data[0].id}/show`);
	}

	const CreateToolbar = props => (
		<Toolbar {...props}>
	
			<SaveButton disabled={props.pristine} />		
			<Button component={ Link } to="/settings" style = {{color:"blue",position:'absolute',right:'15px'}}>Cancel</Button>	
		</Toolbar>
	)

	return <SaveContextProvider value="locations">
		<Create {...props} onCancel={() => redirect('/settings')} title={<span>Create new location</span>}>
			<SimpleForm toolbar={<CreateToolbar/>} save={submit}>
				<h3>Location details</h3>

				<div className = 'form-group'>
					<p style = {{minWidth: '150px',marginRight:'25px'}}>Location Name *</p>
					<TextInput helperText="Location Name" source="name" label="" variant="outlined" validate={[required()]} />
				</div>
			</SimpleForm>
		</Create>
	</SaveContextProvider>
}

const LocationsList = (props) => {
	const Empty = props => (
		<div>
			<Typography class="location-title" style={{ width: "50%", marginLeft:"18px", display:"inline-block" }} component="h2" variant="h5">Locations</Typography>
			<CreateButton basePath={'locations'} style={{ margin: '0 10px', float:'right', display:"inline-block", marginTop:"15px"}} icon={<AddIcon/>}/>
		</div>
			
	);

	return (
		<List basePath="/locations" title=' ' sort={{field:'name', order: 'ASC'}}
		actions={<ListToolbar />} bulkActionButtons={false} empty={<Empty/>}>
			<Datagrid rowClick="edit">
				<TextField source="name" label="Title" />
			</Datagrid>
		</List>)
};

const LocationEdit = (props) => {
	const redirect = () => `/settings`;

	const refresh = useRefresh();

	const EditToolbar = props => (
		<Toolbar {...props}>
		<SaveButton/>
		<DeleteButton
			redirect={redirect}
			style={{position:'absolute',right:'15px'}}
		/>
		</Toolbar>
	)

	return (
	<Edit {...props} title="Update Location" redirect={redirect}>
		<SimpleForm variant="outlined" redirect={redirect} toolbar={<EditToolbar />}>
			<div className='form-group'>
				<p style={{ minWidth: '150px', marginRight: '25px' }}>Name</p>
				<TextInput source="name" label="" validate={[required()]} variant="outlined" />
			</div>
		</SimpleForm>
	</Edit>)
};

export { LocationCreate, LocationsList, LocationEdit };