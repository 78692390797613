const apiUrl = process.env.REACT_APP_API_URL;

const public_url = ["login", "forgot-password", "reset-password", "setup-account", "account-verification"];

const createHeader = data => new Headers({
	...data,
	'Authorization': 'Bearer ' + JSON.parse(JSON.stringify(localStorage.getItem('token'))),
	'Content-Type': 'application/x-www-form-urlencoded'
});

export default {
	login: async ({ username, password }) => {
		const request = new Request(`${apiUrl}/auth/login`, {
			method: 'POST',
			body: JSON.stringify({ username, password }),
			headers: new Headers({ 'Content-Type': 'application/json' }),
		});
		let body = new URLSearchParams();
		return await fetch(request)
			.then(response => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then(data => {
				data = data.data;
				localStorage.setItem('token', data.token.access_token);
				localStorage.setItem('verified', false);
				localStorage.setItem('permissions', data.user.access_type);
				localStorage.setItem('id', data.user.id);
				const now = new Date();
				now.setSeconds(now.getSeconds() +  data.token.expires_in - 60); // give 1 minute leeway
				localStorage.setItem('expire', now.getTime());
				// return Promise.resolve();
			})
			.then(async() =>{
					body.append('work_coach_id',localStorage.getItem('id'));
					await fetch(`${apiUrl}/auth/twoFactor`, {
					method: 'POST',
					headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
					body: body
				})
				return Promise.resolve();
			}

			)
		// return Promise.resolve();
	},

	authAccount: async (code) => {
		const request = new Request(`${apiUrl}/auth/processVerify`, {
			method: 'POST',
			body: JSON.stringify({ code: code }),
			headers: createHeader()
		});
		
		return await fetch(request)
			.then(response => response.json())
			.then(response => response.message ? Promise.resolve() : Promise.reject(response.error))
	},

	auth2fa: async (code) => {
		const request = new Request(`${apiUrl}/auth/processTwoFactor`, {
			method: 'POST',
			body: `code=${code}`,
			headers: createHeader()
		});

		return await fetch(request)
			.then(response => response.json())
			.then(response => {
				if (response.message) {
					localStorage.setItem('verified', true);
					return Promise.resolve();
				} else {
					localStorage.setItem('verified', false);
					return Promise.reject();
				}
			});
	},

	checkError: (error) => { /* ... */ },

	checkAuth: () => {
		if (localStorage.getItem('token')) {
			if (localStorage.getItem('verified') === 'false')
				return Promise.reject({ redirectTo: '/2fa' });

			else {
				return Promise.resolve();
				
				// const expire = localStorage.getItem('expire');
				// if (expire && expire >= new Date().getTime())
				// 	return Promise.resolve();
				// else
				// 	return Promise.reject("Your session has expired, please log in again");
			}
		}
		// this will check for the top level subdomain only (i.e. ignore everything after / and ?)
		else if (public_url.includes(window.location.href.split('/#/')[1].split('/')[0].split('?')[0])) {
			return Promise.resolve();
		}
		else {
			return Promise.reject("You are not logged in");
		}
	},

	logout: async () => {
		const request = new Request(`${apiUrl}/auth/logout`, {
			method: 'POST',
		});
		return await fetch(request)
			.then(response => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then(() => {
				localStorage.clear();
				return Promise.resolve();
			})
	},

	getIdentity: () => { /* ... */ },

	getPermissions: () => {
		if (public_url.includes(window.location.href.split('/#/')[1].split('/')[0].split('?')[0]))
		{
			return Promise.resolve();
		}
		else{
			
			const role = localStorage.getItem('permissions');
			return role ? Promise.resolve(parseInt(role)) : Promise.reject("You don't have the permission to access this page");
		}

	},

	forgotPassword: async (email) => {
		const request = new Request(`${apiUrl}/auth/[PLACEHOLDER_URL_FOR_FORGOT_PASSWORD]`, {
			method: 'POST',
			body: JSON.stringify({ email }),
			headers: new Headers({ 'Content-Type': 'application/json' }),
		});
		return await fetch(request)
			.then(response => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then(() => {
				return Promise.resolve();
			})
	},

	resetPassword: async (password) => {
		const request = new Request(`${apiUrl}/auth/[PLACEHOLDER_URL_FOR_RESET_PASSWORD]`, {
			method: 'POST',
			body: JSON.stringify({ password }),
			headers: new Headers({ 'Content-Type': 'application/json' }),
		});
		return await fetch(request)
			.then(response => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then(data => {
					return data.error ? Promise.resolve() : Promise.reject(data.error);
			})
	}
};