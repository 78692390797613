import React from 'react';
import {
	TextInput,
	Filter,SelectInput
} from 'react-admin';

export default (props) => (
	<Filter {...props}>
        <SelectInput source="date_range" initialValue={"7days"} allowEmpty = {false} choices={[
            { id: '7', name: '7days' },
            { id: '15', name: '15days' },
            { id: '30', name: '30days' },
        ]} alwaysOn onChange={e => props.setRange(e.target.value)}/>
        {/* <SelectInput source="status" alwaysOn choices={[
            { id: true, name: 'Success' },
            { id: false, name: 'Failed' },
        ]}/> */}
	</Filter>
);
