import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useLogin, useNotify, Notification,useRedirect } from 'react-admin';
import dataProvider from '../networking/DataProvider'

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export default () => {
	const classes = useStyles();

	const [email, setEmail] = useState();
	const [password1, setPassword1] = useState();
	const [password2, setPassword2] = useState();
	const [passwordIsMatch, setPasswordIsMatch] = useState();

	const notify = useNotify();
	const redirect = useRedirect();
	const url = process.env.REACT_APP_API_URL;
	const query = new URLSearchParams(window.location.search);

	const onSubmit = (e) => {
		e.preventDefault();

		let body = new URLSearchParams();
		let resetUrl = window.location.href;
		let token = resetUrl.toString().substring(resetUrl.indexOf("=")+1,resetUrl.toString().length);
		console.log(resetUrl);
		body.append('reset_token',token);
		body.append('password',password1);
		body.append('password_confirm', password2);

		fetch(`${url}/auth/updatePassword`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: body		
		})
		.then(response => response.json())
		.then(data =>{
			data.error?notify(data.error.message):notify(data.message);
			data.message&&redirect('/login');
		}
		);
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					New password
        </Typography>
				<form className={classes.form} onSubmit={onSubmit}>
				
				<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						onChange={e => {
							setPassword1(e.target.value);
							if(password2==e.target.value)
							{
								setPasswordIsMatch(true);
							}
							else{
								setPasswordIsMatch(false);
							}
						}
						}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Retype password"
						type="password"
						id="retype-password"
						onChange={e => {
							setPassword2(e.target.value);
							if(password1==e.target.value)
							{
								setPasswordIsMatch(true);
							}
							else{
								setPasswordIsMatch(false);
							}
						}}
					/>
					{!passwordIsMatch&&password2&&<p style={{color:'red'}}>Passwords don't match</p>}
					{/* <FormControlLabel
						control={<Checkbox value="remember" color="primary" />}
						label="Remember me"
					/> */}
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						disabled = {(!passwordIsMatch&&password2)?true:false}
					>
						Submit
          </Button>
				</form>
			</div>
			<Notification />
		</Container>
	);
}