/*
 * @Author: your name
 * @Date: 2021-04-19 16:06:50
 * @LastEditTime: 2021-04-19 17:40:35
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /admin/src/pages/Messages.js
 */
import { Button, Typography, useTheme } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import {
	List,
	Datagrid,
	TextField,
	TextInput,
	Filter,
	FunctionField,
	Show,
	SimpleShowLayout,
	NumberField,
	ArrayField,
	ReferenceArrayField,
	ReferenceField,
	EditButton,
	downloadCSV,
	useNotify,
	DateField,
	ListContextProvider,
	BulkExportButton,
} from 'react-admin';
import DateFilter from '../components/DateFilter';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DataProvider from '../networking/DataProvider';
import Papa from 'papaparse';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReplayIcon from '@material-ui/icons/Replay';

const ExpandedPanel = (props) => (
	<Show {...props} component="div" title=" ">
		{props.record.carrier === 'sms' ?

			<SimpleShowLayout>
				<TextField source="recipient" />
				<TextField source="message" />
			</SimpleShowLayout> :

			<SimpleShowLayout>
				<TextField source="recipient" />
				<TextField source="subject" />
				<FunctionField label="Content" render={record => <div dangerouslySetInnerHTML={{ __html: record.content }}></div>} />
			</SimpleShowLayout>}
	</Show>
);

const MessageList = (props) => {
	const styles = useTheme();
	const notify = useNotify();

	const [dateRange, setDateRange] = useState(30);

	// Function to download data to a file
	function download(data, filename, type) {
		var file = new Blob([data], { type: type });
		if (window.navigator.msSaveOrOpenBlob) // IE10+
			window.navigator.msSaveOrOpenBlob(file, filename);
		else { // Others
			var a = document.createElement("a"),
				url = URL.createObjectURL(file);
			a.href = url;
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			setTimeout(function () {
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			}, 0);
		}
	}

	const convertCases = (logs, cases) => {
		const toConvert = logs.map(row => ({
			"Job seeker ID": cases[row.case_id].job_seeker_id,
			"First name": cases[row.case_id].firstname,
			"Last name": cases[row.case_id].lastname,
			"Recipient": row.carrier === 'email' ? row.recipient : `"${row.recipient}"`,
			"Carrier": row.carrier,
			"Scheduled date": moment(row.scheduled_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY'),
			"Retries": row.number_of_retries,
			"Message type": row.type,
			"Email subject": row.subject,
			"Email content": row.content.length > 1000 ? "Content might be a PDF file or too long" : row.content, // this one might have to be changed otherwise it might display a pdf file as content
			"SMS message": row.message
		}));
		download(Papa.unparse(toConvert, { header: true }), "Failed communications.csv");
	}


	const exporter = (resource, fetchRelatedRecords) => {
		fetchRelatedRecords(resource, 'case_id', 'cases').then(cases => convertCases(resource, cases))
	}

	const bulkActionDownload = (resource) => {
		DataProvider.getList('logs', { pagination: { page: 1, perPage: 10000 }, sort: { field: "id", order: "ASC" }, filter: { "date_range": dateRange.toString() } }).then(logs => {
			const toConvert = logs.data.filter(item => resource.find(i => item.id == i) != undefined)
			DataProvider.getMany('cases', { ids: logs.data.map(item => item.case_id) }).then(cases => {
				const ncases = Object.fromEntries(cases.data.map(item => [item.id, { ...item }]));
				convertCases(toConvert, ncases);
			})
		})
	}

	const bulkActionResend = (resource) => {
		DataProvider.getList('logs', { pagination: { page: 1, perPage: 10000 }, sort: { field: "id", order: "ASC" }, filter: { "date_range": dateRange.toString() } }).then(logs => {
			const toConvert = logs.data.filter(item => resource.find(i => item.id == i) != undefined);
			let emails = [], sms = [];
			toConvert.map(item => item.carrier == 'email' ? emails.push(item.log_ids) : sms.push(item.log_ids));

			emails.length > 0 && DataProvider.post(`resend/email`, { log_ids: JSON.stringify(emails) })
				.then(res => res.data.message && notify("Resent email successful"))
				.catch(e => notify("Resend emails failed, please try again later", "error"))

			sms.length > 0 && DataProvider.post(`resend/sms`, { log_ids: JSON.stringify(sms) })
				.then(res => res.data.message && notify("Resend SMS successful"))
				.catch(e => notify("Resend SMS failed, please try again later", "error"))
		})
	}

	const MessageBulkActions = props => {
		return <Fragment>
			<Button color="primary" onClick={() => bulkActionResend(props.selectedIds)} style={{ marginRight: '10px' }}><ReplayIcon /> Resend selected messages</Button>
			<Button color="primary" onClick={() => bulkActionDownload(props.selectedIds)}><GetAppIcon /> Download selected messages as CSV</Button>
		</Fragment>
	}

	const ActionButton = props => {
		// console.log(props);
		return <ReferenceField {...props} source="case_id" reference="cases">
			<FunctionField render={record => <Link style={{ color: styles.palette.primary.main, textDecoration: "none" }} color="primary" to={`/cases/${record.id}/resend/${props.record.carrier}/${props.record.log_ids}`}>EDIT & RESEND</Link>} />
		</ReferenceField>
	}

	return (
		<List filters={<DateFilter setRange={val => setDateRange(val)} />} bulkActionButtons={<MessageBulkActions />} exporter={exporter} filterDefaultValues={{ date_range: dateRange }} {...props} title="Failed messages">
			<Datagrid rowClick="expand" expand={<ExpandedPanel />} >
				{/* <TextField source="id" label="Message type" /> */}
				<ReferenceField source="case_id" reference="cases" link="show" label="Job seeker ID">
					<TextField source="job_seeker_id" />
				</ReferenceField>
				<TextField source="type" label="Message type" />
				<FunctionField label="Carrier" render={data => data.carrier.charAt(0).toUpperCase() + data.carrier.slice(1)} />
				<DateField locales="en-GB" source="scheduled_date" label="Initially scheduled date" />
				<DateField locales="en-GB" source="created_at" label="Last sent" />
				{/* <FunctionField label="Status" render={record => record.completed_at ? <span style={{ color: "#77BF43" }}>Success</span> : <span style={{ color: "#FF1744" }}>Failed</span>} /> */}
				<NumberField source="number_of_retries" label="Retries" />
				<ActionButton label="Action" textAlign="right" />
			</Datagrid>
		</List>)
};





export { MessageList };