import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useNotify, Notification } from 'react-admin';
import authProvider from '../networking/AuthProvider';
import dataProvider from '../networking/DataProvider';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: '#F34265',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		backgroundColor: '#F34265',
		color: 'white'
	},
}));

export default () => {
	const classes = useStyles();

	const [code, setCode] = useState();

	const notify = useNotify();
	const url = process.env.REACT_APP_API_URL;

	const onSubmit = (e) => {
		e.preventDefault();	
		let body = new URLSearchParams();
		body.append('email', code);
		fetch(`${url}/auth/resetPassword`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: body		
		})
		.then(response => response.json())
		.then(data =>data.error? notify(data.error.message, "errors") : notify(data.message));
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Forgot password
        </Typography>
				<br />
				<Typography component="div" variant="p">
					We will send you the password reset link to your email
        </Typography>
				<form className={classes.form} onSubmit={onSubmit}>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email"
						name="code"
						autoComplete="code"
						autoFocus
						color='secondary'
						onChange={e => setCode(e.target.value)}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color='secondary'
						className={classes.submit}
					>
						Submit
          </Button>
					<Grid container>
						<Grid item xs>
							<Link to="/login" variant="body2" style={{ textDecoration: 'none', color: '#14292F' }}>
								Back to login
              </Link>
						</Grid>
					</Grid>
				</form>
			</div>
			<Notification />
		</Container>
	);
}