import React, { useEffect, useState } from 'react';
import {
	FunctionField,
	Show,
	SimpleShowLayout,
	ResourceContextProvider,
	Datagrid,
	Title,
	ReferenceField,
	List as RAList,
	ListContextProvider,
	ListGuesser,
	DateField,
	SimpleForm,
	AutocompleteInput,
	ReferenceInput,
	required,
	EmailField,
	useRefresh
} from 'react-admin';
import { Line, Bar,Scatter,HorizontalBar } from 'react-chartjs-2'
import { chartOptions, chartDatapoints, tooltipConfig} from '../components/ChartConfig'
import dataProvider from '../networking/DataProvider'
import { Link } from "react-router-dom";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TextField, Typography } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import keyBy from 'lodash/keyBy';
import 'chartjs-plugin-datalabels';
import { debounce } from 'lodash';

function simpleStringify(object) {
	
	var simpleObject = {};
	for (var prop in object) {
		if (!object.hasOwnProperty(prop)) {
			continue;
		}
		if (typeof (object[prop]) == 'object') {
			continue;
		}
		if (typeof (object[prop]) == 'function') {
			continue;
		}
		simpleObject[prop] = object[prop];
	}
	return JSON.stringify(simpleObject); // returns cleaned up JSON
};


const ReassignShow = (props) => {

	const [data, setData] = useState([]);//Data used for generating scatter graph
	const [useCase, setUseCase] = useState([]);//Data used for fetching users
	const [selectedUsers, setSelectedUsers] = useState([]);//Users with selected result
	const [resAssign, setResAssign] = useState();
	const [usersVar, setUsersVar]=useState();

	const refresh = useRefresh();

	// useEffect(() => {
	// 	fetchData();fetchUsers(2);
	// }, [])

	useEffect(() => {
		fetchStats(0);
		//fetchUsers();
	}, [])


	function queryString()
	{
		const hash = window.location.hash;
		const query = hash.substring(hash.indexOf("?") + 1);
		const queryString = require('query-string');
		var ids  = queryString.parse(query, {arrayFormat: 'index'});
		ids  = JSON.stringify(ids).replace(/['"{}]/g, '').replace(/[:]/g, '=');
		return ids;
	}

	const unquotedStringfy = (object) =>
	{
		return JSON.parse(JSON.stringify(object));
	}
	
	const config = {
		tooltips: {
			callbacks: {
				label: (item, data) => {
					data = data.datasets[0].data;
					return [`Confidence: ${item.label}`, `Motivation: ${item.value}`, `Cases with selected result: ${data[item.index].count}`];
				},
			},
			...tooltipConfig
		}
	}

	const barData = {
		labels : ['Independence','Excite','Ignite','Guide'],
		datasets: [
		{
			backgroundColor: [
			'#70afcf',
			'#f1cc53',
			'#14292F',
			'#f37a7a',
			],
		data: [ 
			useCase.filter(value=>{
			//divide the amount of use cases in particular quadrant by the total amount of use cases
			return value.x>=9.5 && value.y>9.5;
			}).reduce((sum, record) => sum + record.count,0)/(useCase.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)) * 100, 
			useCase.filter(value=>{
				return value.x>9.5 && value.y<=9.5;
				}).reduce((sum, record) => sum + record.count,0)/(useCase.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)) * 100, 
			useCase.filter(value=>{
				return value.x<=9.5 && value.y<9.5;
				}).reduce((sum, record) => sum + record.count,0)/(useCase.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)) * 100,  
			useCase.filter(value=>{
				return value.x<9.5 && value.y>=9.5;
				}).reduce((sum, record) => sum + record.count,0)/(useCase.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0)) * 100
			],
			fill:false
		}
		]
	};


	const plugins = [{
		beforeDraw: (chartInstance, easing) => {
			const ctx = chartInstance.chart.ctx;
			const scaleX = chartInstance.scales['x-axis-0'];
			const scaleY = chartInstance.scales['y-axis-0'];
			ctx.beginPath();
			ctx.moveTo(scaleX.left,scaleY.bottom/2.17+5);
			ctx.lineTo(scaleX.right, scaleY.bottom/2.17+5);
			ctx.strokeStyle = 'grey';
			ctx.stroke();
			ctx.beginPath();
			ctx.moveTo(scaleX.right/1.9+35,scaleY.top);
			ctx.lineTo(scaleX.right/1.9+35, scaleY.bottom);
			ctx.strokeStyle = 'grey';
			ctx.stroke();
		}
	}];

	const fetchStats = (value) => dataProvider.get(`statistics?case_ids=[${props.selected}]`).then(response => {
		setResAssign(response.data);
		let ds = [];

		for (const item in response.data.groupByScore) {
			for(const itemx in response.data.groupByScore[item]){
				for (const itemy in response.data.groupByScore[item][itemx]){
					ds.push(response.data.groupByScore[item][itemx][itemy]);
				}
			}
			
		}
		setSelectedUsers(ds);
		setUsersVar(ds);
	})

	const sendSearch = debounce(value => fetchStats(value), 300);

	const onSearch = (value) => {
		sendSearch(value);
	}

	const submit = data => {
		var submitted=0;

		
		for (var user of selectedUsers) {
			var dataSubmit = {
				firstname:user.firstname,
				lastname:user.lastname,
				phone:user.phone,
				email:user.email,
				case_work_coach_id:data.case_work_coach_id,
				job_seeker_id:user.job_seeker_id
			};

			dataProvider.put(`cases/`+user.case_id, dataSubmit)
			.then(res => res.data)
			.then(data => {
				if (data.error)
					console.log(data.error);
					//notify(data.error.message, "error");
				else {
					submitted +=1;
					if(submitted==selectedUsers.length){
						
						//refresh();
						props.close();
						//setShowModal(false);
					}
				}
			});
		}
	};

	return (
		<div>
			<SimpleForm save={submit} >
				<h3>Update Job Coach</h3>
				<div className = 'form-group'>
					<p style = {{minWidth: '150px',marginRight:'25px'}}>New job coach *</p>
					<ReferenceInput label="" source="case_work_coach_id" reference="workcoaches" sort={{field:'lastname', order: 'ASC'}} filter={{ deleted_status: false }}>
						<AutocompleteInput variant="outlined" label="" optionText={record => `${record.firstname} ${record.lastname} - ${record.username}`}  validate={[required()]}/>
					</ReferenceInput>
				</div>

			</SimpleForm>
			<ResourceContextProvider value="statistics">
				{ <Title title="Reassign" /> }
				<div className='statistics-card'>
					<ListContextProvider value={{
						data: keyBy(selectedUsers, 'id'),
						ids: selectedUsers.map(item => item.id),
						basePath: "/cases",
						resources: "cases",
						total: selectedUsers.length,
						page: 1,
						perPage: selectedUsers.length,
						setPage: 1,
						currentSort: { field: 'id', order: 'ASC' }
					}}>
						<Datagrid rowClick="show">
							<ReferenceField label="Case" source="id" reference="cases" link="show">
								<FunctionField render={record => `${record.firstname} ${record.lastname}`} />
							</ReferenceField>
							<EmailField source="email" />
							<ReferenceField label="Assigned work coach" source="case_work_coach_id" reference="workcoaches">
								<FunctionField render={record => `${record.firstname} ${record.lastname} - ${record.username}`} />
							</ReferenceField>
						</Datagrid>
					</ListContextProvider>
				</div>
			</ResourceContextProvider>
		</div>
		
	)
}

export { ReassignShow };