import React, { useState,useEffect } from 'react';
import { Button, Card, TextField, Toolbar, Typography} from '@material-ui/core';
import {
	NumberInput,
	ResourceContextProvider,
	SaveButton,
	SimpleForm,
	SimpleShowLayout,
	Title,
	useNotify,
	required,
	Datagrid,
	List,
	Resource
} from 'react-admin';
import SaveIcon from '@material-ui/icons/Save'
import dataProvider from '../networking/DataProvider'
import {LocationsList} from './Locations';
import { SurveyList, SurveyShow } from './Survey';






export default props => {
	const notify = useNotify();
	
	const [defaultNum, setDefaultNum] = useState();
	const [defaultWeight] = useState(1);
	const [defaultReminder, setDefaultReminder] = useState();
	const [val, setVal] = useState();
	const [valReminder, setValReminder] = useState();
	const [q1, setQ1] = useState();
	const [q2, setQ2] = useState();
	const [q3, setQ3] = useState();
	const [q4, setQ4] = useState();
	const [q5, setQ5] = useState();
	const [q6, setQ6] = useState();
	const [low1, setLow1] = useState();
	const [low2, setLow2] = useState();
	const [q1Initial, setQ1Initial] = useState();
	const [q2Initial, setQ2Initial] = useState();
	const [q3Initial, setQ3Initial] = useState();
	const [q4Initial, setQ4Initial] = useState();
	const [q5Initial, setQ5Initial] = useState();
	const [q6Initial, setQ6Initial] = useState();
	const [low1Initial, setLow1Initial] = useState();
	const [low2Initial, setLow2Initial] = useState();

	const [locations,setLocations] = useState();




	const BottomToolbar = props => (
		<Toolbar {...props}>
			<Button
			variant="contained"
			color="primary"
			size="large"
			startIcon={<SaveIcon />}
			onClick = {handleClick}
			disabled={(!defaultNum | defaultNum <= 0 | !val | val <= 0) && (!defaultReminder | defaultReminder <= 0 | !valReminder | valReminder <= 0) }
		  >Save</Button>
		</Toolbar>
	)
	const AlgorithmBottomToolbar = props => (
		<Toolbar {...props}>
			<Button
			variant="contained"
			color="primary"
			size="large"
			startIcon={<SaveIcon />}
			onClick = {saveAlgorithm}
			disabled={(!q1 && !q2 && !q3 && !q4 && !q5 && !q6) }
		  >Save</Button>
		</Toolbar>
	)
	const handleClick = async() => {
		var parameters = "settings?";
		if(val){
			parameters += 'days='+val;
		}

		if(valReminder){
			if(val){
				parameters += '&';
			}
			parameters += 'weeks='+valReminder;
		}
		const response = await dataProvider.put(parameters);
		if (response.data.status === 'success')
			notify('Updated');
		else
			notify('Update failed, please try again', "error");
	}

	const saveAlgorithm = async() => {
		var parameters = "weightlowscorevalue?";

		var putVars = [];
		if(q1){
			putVars.push('question1_weighting='+q1)
		} else {
			putVars.push('question1_weighting='+q1Initial)
		}
		if(q2){
			putVars.push('question2_weighting='+q2)
		} else {
			putVars.push('question2_weighting='+q2Initial)
		}
		if(q3){
			putVars.push('question3_weighting='+q3)
		} else {
			putVars.push('question3_weighting='+q3Initial)
		}
		if(q4){
			putVars.push('question4_weighting='+q4)
		} else {
			putVars.push('question4_weighting='+q4Initial)
		}
		if(q5){
			putVars.push('question5_weighting='+q5)
		} else {
			putVars.push('question5_weighting='+q5Initial)
		}
		if(q6){
			putVars.push('question6_weighting='+q6)
		} else {
			putVars.push('question6_weighting='+q6Initial)
		}
		if(low1){
			putVars.push('lowscore1='+low1)
		} else {
			putVars.push('lowscore1='+low1Initial)
		}
		if(low2){
			putVars.push('lowscore2='+low2)
		} else {
			putVars.push('lowscore2='+low2Initial)
		}

		var encodedParameters = putVars.join('&');
		parameters += encodedParameters;
		const response = await dataProvider.put(parameters);

		if (response.status === 'success')
			notify('Updated');
		else
			notify('Update failed, please try again', "error");
	}
	
	useEffect(() => console.log(defaultNum), [defaultNum])

	useEffect(() =>{
		getDefaultNum();
	},[]);

	const getDefaultNum = async() =>{
		const response = await dataProvider.get('settings');

		response.data && setDefaultNum(response.data[0].value);

		response.data.forEach((el)=>{
			if(el.key=="weeks"){
				setDefaultReminder(el.value);
			}
		});

		const responseSettings = await dataProvider.get('weightlowscorevalue');

		console.log("Getting weight")
		setQ1Initial(responseSettings.data[0].question1_weighting);
		setQ2Initial(responseSettings.data[0].question2_weighting);
		setQ3Initial(responseSettings.data[0].question3_weighting);
		setQ4Initial(responseSettings.data[0].question4_weighting);
		setQ5Initial(responseSettings.data[0].question5_weighting);
		setQ6Initial(responseSettings.data[0].question6_weighting);
		setLow1Initial(responseSettings.data[0].lowscore1);
		setLow2Initial(responseSettings.data[0].lowscore2);
		console.log(responseSettings)
		console.log(responseSettings.data[0].question1_weighting);
	}

	

	const notNegative = data => data > 0 ? undefined : "Must be a positive number"

	return(
		<div>
			<Card>
				<Title defaultTitle="Settings" />
				<SimpleForm toolbar={<BottomToolbar />}>
					<Typography style={{ width: "100%" }}>Default number of days to send out surveys in advanced​ before next meeting</Typography>
					<NumberInput key={defaultNum} size="small" source="num" label="" variant="outlined" min={1}
						defaultValue={defaultNum} validate={[required(), notNegative]} onChange={e => setVal(parseInt(e.target.value))} />
					<Typography style={{ width: "100%" }}>Number of weeks to wait before sending reminder</Typography>
					<NumberInput key={defaultReminder} size="small" source="reminder" label="" variant="outlined" min={1}
						defaultValue={defaultReminder} validate={[required(), notNegative]} onChange={e1 => setValReminder(parseInt(e1.target.value))} />
				</SimpleForm>
			</Card>
			<Card style={{marginTop: '15px'}}>
				<SimpleForm toolbar={<AlgorithmBottomToolbar />}>
					<Typography style={{ width: "100%" }}>Question 1 Weighting</Typography>
					<NumberInput key={q1Initial} size="small" source="q1" label="" variant="outlined" min={0} step="0.1"
						defaultValue={q1Initial} validate={[required(), notNegative]} onChange={e => setQ1(parseFloat(e.target.value))} />
					<Typography style={{ width: "100%" }}>Question 2 Weighting</Typography>
					<NumberInput key={q2Initial} size="small" source="q2" label="" variant="outlined" min={0} step="0.1"
						defaultValue={q2Initial} validate={[required(), notNegative]} onChange={e => setQ2(parseFloat(e.target.value))} />
					<Typography style={{ width: "100%" }}>Question 3 Weighting</Typography>
					<NumberInput key={q3Initial} size="small" source="q3" label="" variant="outlined" min={0} step="0.1"
						defaultValue={q3Initial} validate={[required(), notNegative]} onChange={e => setQ3(parseFloat(e.target.value))} />
					<Typography style={{ width: "100%" }}>Question 4 Weighting</Typography>
					<NumberInput key={q4Initial} size="small" source="q4" label="" variant="outlined" min={0} step="0.1"
						defaultValue={q4Initial} validate={[required(), notNegative]} onChange={e => setQ4(parseFloat(e.target.value))} />
					<Typography style={{ width: "100%" }}>Question 5 Weighting</Typography>
					<NumberInput key={q5Initial} size="small" source="q5" label="" variant="outlined" min={0} step="0.1"
						defaultValue={q5Initial} validate={[required(), notNegative]} onChange={e => setQ5(parseFloat(e.target.value))} />
					<Typography style={{ width: "100%" }}>Question 6 Weighting</Typography>
					<NumberInput key={q6Initial} size="small" source="q6" label="" variant="outlined" min={0} step="0.1"
						defaultValue={q6Initial} validate={[required(), notNegative]} onChange={e => setQ6(parseFloat(e.target.value))} />
					<Typography style={{ width: "100%" }}>If score is less than: </Typography>
					<NumberInput key={low1Initial} size="small" source="low1" label="" variant="outlined" min={0}
						defaultValue={low1Initial} validate={[required(), notNegative]} onChange={e => setLow1(parseInt(e.target.value))} />
					<Typography style={{ width: "100%" }}>reduce by: </Typography>
					<NumberInput key={low2Initial} size="small" source="low2" label="" variant="outlined" min={0}
						defaultValue={low2Initial} validate={[required(), notNegative]} onChange={e => setLow2(parseInt(e.target.value))} />
				</SimpleForm>
			</Card>
			<Card style={{marginTop: '15px'}}>
				<Resource name='locations' list={LocationsList}/>
			</Card>
		</div>
		
	)
}