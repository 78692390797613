import React, { useEffect, useState, Fragment } from 'react';
import {
	List,
	Datagrid,
	TextField,
	FunctionField,
	Show,
	SimpleShowLayout,
	NumberField,
	ArrayField,
	DateField,
	ReferenceField,
	TextInput,
	DateInput,
	Filter,
	NumberInput,
	useNotify,
	useListContext,
	ListContextProvider,
	ResourceContextProvider,
	Title,
	useResourceContext,
	BulkExportButton
} from 'react-admin';
import { Scatter } from 'react-chartjs-2'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { Slider, Card, Switch, Grid, Button, TextField as MuiTextInput, InputAdornment, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles, useTheme } from '@material-ui/core/styles';
import dataProvider from '../networking/DataProvider';
import { DateRangePicker } from 'materialui-daterange-picker';
import moment from 'moment';
import ClearIcon from '@material-ui/icons/Clear';
import Papa from 'papaparse';
import GetAppIcon from '@material-ui/icons/GetApp';


const moment_date_format = 'YYYY-MM-DD';
const uk_date_format = 'DD-MM-YYYY';

const SurveyFilter = props => {
	const { setFilters } = useListContext();

	const [showSentPicker, setShowSentPicker] = useState(false);
	const [sentDateRange, setSentDateRange] = useState();

	const [showCompletedPicker, setShowCompletedPicker] = useState(false);
	const [completedDateRange, setCompletedDateRange] = useState();

	const convertDate = (date) => moment(date).format(moment_date_format);
	const displayDate = (date) => moment(date, moment_date_format).format(uk_date_format);

	const [round, setRound] = useState();

	useEffect(() => {
		const value = {
			...sentDateRange && sentDateRange.length === 2 && { published_at_start: sentDateRange[0], published_at_end: sentDateRange[1] },
			...completedDateRange && completedDateRange.length === 2 && { completed_at_start: completedDateRange[0], completed_at_end: completedDateRange[1] },
			...round && round > 0 && { round: round }
		}
		setFilters(value, null, true);
	}, [completedDateRange, sentDateRange, round])

	return <Filter {...props} >
		{/* <DateInput label="Sent at" source="published_at" alwaysOn /> */}
		{/* <DateInput label="Completed at" source="completed_at" alwaysOn /> */}
		{/* <NumberInput label="Round" source="round" alwaysOn min={0} validate={validateNumber} /> */}

		<MuiTextInput label="Sent at" variant="filled" size="small" alwaysOn
			onClick={() => setShowSentPicker(!showSentPicker)}
			InputProps={{
				readOnly: true, endAdornment: (sentDateRange && <InputAdornment position="end">
					<IconButton onClick={() => setSentDateRange()}><ClearIcon fontSize="small" /></IconButton></InputAdornment>)
			}}
			value={sentDateRange ? displayDate(sentDateRange[0]) + " to " + displayDate(sentDateRange[1]) : ""} />

		<MuiTextInput label="Completed at" variant="filled" size="small" alwaysOn
			onClick={() => setShowCompletedPicker(!showCompletedPicker)}
			InputProps={{
				readOnly: true, endAdornment: (completedDateRange && <InputAdornment position="end" >
					<IconButton onClick={() => setCompletedDateRange()}><ClearIcon fontSize="small" /></IconButton></InputAdornment>)
			}}
			value={completedDateRange ? displayDate(completedDateRange[0]) + " to " + displayDate(completedDateRange[1]) : ""} />

		<MuiTextInput label="Round" variant="filled" size="small" alwaysOn onChange={e => setRound(e.target.value)} error={round <= 0} />

		<DateRangePicker open={showSentPicker} toggle={() => setShowSentPicker(!showSentPicker)}
			onChange={range => setSentDateRange([convertDate(range.startDate), convertDate(range.endDate)])} alwaysOn />
		<DateRangePicker open={showCompletedPicker} toggle={() => setShowCompletedPicker(!showCompletedPicker)}
			onChange={range => setCompletedDateRange([convertDate(range.startDate), convertDate(range.endDate)])} alwaysOn className="date-picker-overlay" />
	</Filter>
}

const SurveyList = (props) => {

	const exporter = (resource, fetchRelatedRecords, dataProvider) => {
		dataProvider.get('survey-completed','').then(
			json=>convertAPICases(json['data']['completed_survey_list'])
		);
	}

	// Function to download data to a file
	function download(data, filename, type) {
		var file = new Blob([data], { type: type });
		if (window.navigator.msSaveOrOpenBlob) // IE10+
			window.navigator.msSaveOrOpenBlob(file, filename);
		else { // Others
			var a = document.createElement("a"),
				url = URL.createObjectURL(file);
			a.href = url;
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			setTimeout(function () {
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			}, 0);
		}
	}

	const convertAPICases = (surveys) => {
		const toConvert = surveys.map(row => ({
			"Case ID":row.case.job_seeker_id,
			"First Name":row.firstname,
			"Last Name":row.lastname,
			"Work Coach Email":row.username,
			"Question 1 Answer":row.question_1_answer,
			"Question 2 Answer":row.question_2_answer,
			"Question 3 Answer":row.question_3_answer,
			"Question 4 Answer":row.question_4_answer,
			"Question 5 Answer":row.question_5_answer,
			"Question 6 Answer":row.question_6_answer,
			"Confidence":row.confidence,
			"Motivation":row.motivation,
			"Completed At":row.completed_at,
			"Published At":row.published_at,
			"Total Completed":row.total_completed
		}));
		download(Papa.unparse(toConvert, { header: true }), "Completed Cases.csv");
	}

	return (
		<List {...props} filters={<SurveyFilter />} exporter={exporter} title="Surveys" sort={{ field: "published_at", order: "DESC" }}>
				<Datagrid rowClick={(id, basePath, record) => record.completed_at && "show"}>
					<ReferenceField label="Name" source="case_id" reference="cases" link="">
						<FunctionField render={record => `${record.firstname} ${record.lastname}`} />
					</ReferenceField>
					<DateField source="published_at" label="Sent at" locales="en-GB" />
					<DateField source="completed_at" locales="en-GB" emptyText={<span style={{ color: "#FF1744" }}>Not completed</span>} />
					<NumberField source="round" emptyText="-" />
					<NumberField source="confidence" emptyText="-" />
					<NumberField source="motivation" emptyText="-" />
				</Datagrid>
			</List>
	)	
}

const showFile = (blob, name) => {
	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveOrOpenBlob(blob);
		return;
	}

	const data = window.URL.createObjectURL(blob);
	var link = document.createElement('a');
	link.href = data;
	link.download = name;
	link.click();
	setTimeout(function () {
		window.URL.revokeObjectURL(data);
	}, 100);
}

const firstLetterUpper = text => text.charAt(0).toUpperCase() + text.slice(1)

const SurveyShowAside = ({ record, ...props }) => {
	const [message, setMessage] = useState();

	useEffect(() => {
		let data = {};
		if (!props.report)
			setMessage('')

		// if job seeker
		else if (props.userType) {
			data = props.report.jobseeker;
			setMessage(<div>
				<Typography>Results:</Typography>
				<ul>{data.results.map(item => <li><Typography>{firstLetterUpper(item)}</Typography></li>)}</ul>

				<Typography>Tips:</Typography>
				<ul>{data.tips.map(item => <li>
					<Typography>{item.subTitle}</Typography>
					<ul>{item.list.map(i => <li><Typography>{firstLetterUpper(i)}</Typography></li>)}</ul>
				</li>)}</ul>
			</div>)
		}

		// if work coach
		else {
			data = props.report.workcoach
			setMessage(<div>
				<img src={data.image} style={{ display: 'block', margin: 'auto' }} />

				<Typography>Quadrant: {data.quadrant}</Typography>
				<Typography>Result: {data.result}</Typography>
				<Typography>What this means: {data.what_this_means}</Typography>
				<Typography>What you might hear: {data.what_you_might_hear}</Typography>
				<Typography>Your focus: {data.your_focus}</Typography>

				<Typography>Insights: </Typography>
				<ul>{data.insights.map(item => <li><Typography>{firstLetterUpper(item)}</Typography></li>)}</ul>

				<Typography>Coaching tips: </Typography>
				<ul>{data.coaching_tips.map(item => <li><Typography>{firstLetterUpper(item)}</Typography></li>)}</ul>

				<Typography>Coaching questions: </Typography>
				<ul>{data.coaching_questions.map(item => <li><Typography>{firstLetterUpper(item)}</Typography></li>)}</ul>

				<Typography>Coaching activities:</Typography>
				<ul>{data.coach_activities.map(item => <li>
					<Typography>{item.subTitle} - {item.content}</Typography>
					<ul>{item.list.map(i => <li><Typography>{firstLetterUpper(i)}</Typography></li>)}</ul>
				</li>)}</ul>

			</div>);
		}
	}, [props.userType, props.report])

	return <div className="show-box survey-show-box show-right survey-show-right">
		<div>
			<h3>User results: Motivation {record ? record.motivation : ''}, Confidence {record ? record.confidence : ''}</h3>
			<div>{message}</div>
		</div>
	</div>
}

const SurveyShow = (props) => {
	const [data, setData] = useState();
	const [userRecord, setUserRecord] = useState();
	const [userType, setUserType] = useState(false);

	useEffect(() => {
		dataProvider.get(`survey-instances/report/${props.id}`).then(ds => setData(ds))
	}, []);

	const notify = useNotify();

	const downloadPdf = (caseId, date, type, id) => {
		const apiUrl = process.env.REACT_APP_API_URL;

		fetch(`${apiUrl}/survey-instances/${id}/download`, {
			method: 'POST',
			headers: new Headers({
				'Authorization': 'Bearer ' + JSON.parse(JSON.stringify(localStorage.getItem('token'))),
				'Content-Type': 'application/x-www-form-urlencoded',
			}),
			body: new URLSearchParams(`type=${type}`)
		})
			.then(res => res.blob())
			.then(res => res.type === 'application/pdf' ? showFile(res, `${caseId || ""}_${date ? moment(date, moment_date_format).format(uk_date_format) : ""}_${type === 'jobseeker' ? 'Jobseeker' : 'Work coach'}.pdf`) : notify("Cannot download file"))
	}

	const PrettoSlider = withStyles({
		thumb: {
			height: 16,
			width: 16,
			backgroundColor: '#14292F',
			marginTop: -8,
			marginLeft: -8,
			'&:focus, &:hover, &$active': {
				boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
			},
		},
		// active: {},
		// valueLabel: {
		// 	left: 'calc(-50% + 12px)',
		// 	top: -22,
		// 	'& *': {
		// 		background: 'transparent',
		// 		color: '#000',
		// 	},
		// },
		// track: {
		// },
		// rail: {
		// 	opacity: 0.5,
		// 	backgroundColor: '#bfbfbf',
		// },
		// markActive: {
		// 	opacity: 1,
		// 	backgroundColor: 'currentColor',
		// },
	})(Slider);

	// <span>Survey result: &nbsp;&nbsp; {moment(record.completed_at, 'YYYY-MM-DD HH-mm-ss').format('DD/MM/YYYY')}</span>
	
	const ShowTitle = (props) => {
		useEffect(() => console.log('dasjdsal'), [])
		return <ResourceContextProvider value="cases">
			<ReferenceField resource="cases" resource="case_id"><TextField source="firstname"></TextField></ReferenceField>
		</ResourceContextProvider>
	}

	const SurveyToolbar = props => (
		<Grid component="label" container alignItems="center" spacing={1}>
			<Grid item>Work coach</Grid>
			<Grid item><Switch checked={userType} onChange={() => setUserType(!userType)} color="primary" /></Grid>
			<Grid item>Job seeker</Grid>
			<Grid item style={{ marginLeft: '29px' }}>
				<ReferenceField {...props} source="case_id" reference="cases" label=" " link={false}>
					<FunctionField label=" " render={record => userType ?
						<Button color="primary" onClick={() => downloadPdf(record.job_seeker_id, props.record.completed_at, 'jobseeker', props.record.id)}>Download job seeker handout</Button> :
						<Button color="primary" onClick={() => downloadPdf(record.job_seeker_id, props.record.completed_at, 'workcoach', props.record.id)}>Download work coach handout</Button>
					} />
				</ReferenceField>		
			</Grid>
		</Grid>
	)
	
	const TitleField = props => {
		return <ReferenceField {...props} resource="survey-instances" basePath="/survey-instances" source="case_id" reference="cases" label= "Case" link="">
			<FunctionField render={record => <span style={{ fontWeight: 'bold', fontSize: "1.25rem" }}>Survey results: &nbsp;&nbsp; {record.firstname} {record.lastname} - {moment(props.record.completed_at, 'YYYY-MM-DD HH-mm-ss').format('DD/MM/YYYY')}</span>} />
		</ReferenceField>
	}

	return (
		<Show {...props} title={<TitleField />} aside={<SurveyShowAside userType={userType} report={data ? data.report : undefined} />} component="div">
			<SimpleShowLayout className="show-box survey-show-box show-left">
				<SurveyToolbar />
				<br />

				<FunctionField label="Questions" render={record => {
					if (!data) return;

					const questions = Object.entries(record).filter(item => /^question_\d_answer/.test(item[0])).map(item => item[1]); // basically this will filter out "quesiton_[num]_answer" fields
					const script = data.script.questions;

					return questions.map((question, index) =>
						<div className="question-slider-group" key={index}>
							<Typography>{script[index].question_number}. {script[index].question}</Typography >

							<PrettoSlider
								defaultValue={question}
								min={1}
								max={5}
								step={1}
								aria-labelledby="discrete-slider-custom"
								marks={true}
								disabled={true}
								valueLabelDisplay='auto'
							/>
							<div className="survey_scale">
								<p>{script[index].lowest}</p>
								<p>{script[index].highest}</p>
							</div>
						</div>)
				}} />

			</SimpleShowLayout>
		</Show>
	)
}


export { SurveyList, SurveyShow };