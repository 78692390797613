import React from 'react';
import {
    Button,
} from 'react-admin';
import { Link } from "react-router-dom";
import { stringify } from 'query-string';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { checkPropTypes } from 'prop-types';

const ReassignJobCoach = (props) => {
    // const refresh = useRefresh();
    // const notify = useNotify();
    // const unselectAll = useUnselectAll();
    // const [updateMany, { loading }] = useUpdateMany(
    //     'posts',
    //     selectedIds,
    //     { views: 0 },
    //     {
    //         onSuccess: () => {
    //             refresh();
    //             notify('Posts updated');
    //             // unselectAll(resource);
    //         },
    //         onFailure: error => notify('Error: posts not updated', 'warning'),
    //     }
    // );

    function showStatistics(){
        props.handlerReassign(props.selectedIds);
    }

    return (
       
            <Button
                label={"Change Job Coach"}
                onClick={showStatistics}
            >
                <ShowChartIcon></ShowChartIcon>
                {/* <VisibilityOff /> */}
            </Button>
 
    );
};

export default ReassignJobCoach;