import './App.css';
import { useState } from 'react';
import { Admin, Resource, ListGuesser, ShowGuesser, EditGuesser, defaultTheme } from 'react-admin';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';

import { Route } from 'react-router-dom';
import dataProvider from './networking/DataProvider';
import authProvider from './networking/AuthProvider';

import Login from './pages/Login';
import Login2FA from './pages/Login2FA';
import { CaseCreate, CaseEdit, CaseList, CaseShow, CaseSurvey, CaseResend } from './pages/Case';
import { SurveyList, SurveyShow } from './pages/Survey';
import { QuestionnaireEdit } from './pages/Questionnaire'
import { StatisticsShow } from './pages/Statistics';
import { CoachCreate, CoachEdit, CoachList, CoachShow } from './pages/Coaches';
import { MessageList } from './pages/Messages';
import { ProfileEdit } from './pages/Profile';
import { LocationCreate, LocationEdit } from './pages/Locations';
import ResetPassword from './pages/ResetPassword';
import ForgotPassword from './pages/ForgotPassword';
import Settings from './pages/Settings';
import CreatePassword from './pages/CreatePassword';
import AuthAccount from './pages/AuthAccount';
import Unauthorized from './components/Unauthorized';
import {LateSurveyList} from './components/LateSurveys';

import Layout from './components/Layout'

import PeopleIcon from '@material-ui/icons/People';
import TimelineIcon from '@material-ui/icons/Timeline';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import EmailIcon from '@material-ui/icons/Email';
import SettingsIcon from '@material-ui/icons/Settings';

function App() {
  const theme = createMuiTheme({
    palette: {
      primary: { main: '#14292F', contrastText: '#FFF' },
      secondary: { main: '#F34265', contrastText: '#FFF' },
      error: { main: '#DC004E', contrastText: '#FFF' }
    },
    typography: {
      fontWeightRegular: 500,
      fontFamily: "Montserrat, Arial, Helvetica, sans-serif",
      // h1: { fontWeight: 'bold' },
      // h2: { fontWeight: 'bold' },
      // h3: { fontWeight: 'bold' },
      // h4: { fontWeight: 'bold' },
      // h5: { fontWeight: 'bold' },
      // h6: { fontWeight: 'bold' },
    },
    overrides: {
      RaAutocompleteSuggestionList: {
        suggestionsContainer: {
          zIndex: 9000,
        },
      },
    },
  })

  return (
    <Admin theme={theme} layout={Layout}
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={Login}
      locale="en_gb"
      customRoutes={[
        <Route exact path="/2fa" component={Login2FA} noLayout />,
        <Route exact path="/forgot-password" component={ForgotPassword} noLayout />,
        <Route exact path="/setup-account" component={CreatePassword} noLayout />,
        <Route exact path="/reset-password" component={ResetPassword} noLayout />,
        <Route exact path="/account-verification" component={AuthAccount} noLayout />,
        <Route path="/cases/:id/schedule" render={() => <CaseSurvey id=":id" />} />,
        <Route path="/cases/:id/resend/:carrier/:logId" render={() => <CaseResend id=":id" logId=":logId" carrier=":carrier" />} />,
        <Route path="/profile" component={ProfileEdit} />,
        <Route path="/statistics" component={StatisticsShow} />
      ]}>
      {permissions => [
        <Resource name='cases'options={{ label: "Jobseekers" }} list={CaseList} show={CaseShow} edit={CaseEdit} create={CaseCreate} icon={AssignmentIndIcon} />,
        <Resource name='survey-instances' list={SurveyList} show={SurveyShow} icon={PlaylistAddCheckIcon} options={{ label: "Survey" }} />,
        <Resource name='survey-instance-history' />,
        <Resource name='communication-history' />,
        <Resource name='logs' options={{ label: "Messages" }} list={MessageList} icon={EmailIcon} />,
        <Resource name='profile' />,
        <Resource name='workcoach_case_reminders' list={LateSurveyList} />,
        <Resource name='locations' create={LocationCreate} edit={LocationEdit} />,
        <Resource name='workcoaches' list={CoachList} edit={permissions < 3 ? CoachEdit : Unauthorized} {...{ create: permissions < 3 && CoachCreate }} icon={PeopleIcon} options={{ label: "Coaches" }} />,
        permissions < 3 && <Resource name='settings' list={Settings} icon={SettingsIcon} />,
      ]}
    </Admin>
  );
}

export default App;
